import React from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { mapBlogs } from '../../lib/utility';
import { imgBrand, alt as altText, isBe } from '../../lib/brand';

import Layout from '../../components/Layout';
import Header from '../../components/Header';
import HeroLarge from '../../components/HeroLarge';
import IntroBlock from '../../components/IntroBlock';
import Types from '../../components/Types';
import BgImage from '../../components/BgImage';
import BlogRoll from '../../components/BlogRoll';
import FindLO from '../../components/FindLO';

// Home loan types icons
// active
var icons_Jumbo_active = imgBrand('typesImg/Icons_Jumbo_active.png');
var icons_USDA_active = imgBrand('typesImg/Icons_USDA_active.png');
var icons_Reverse_active = imgBrand('typesImg/Icons_ReverseMortgage_active.png');
// inactive
var icons_Jumbo_inactive = imgBrand('typesImg/Icons_Jumbo_inactive.png');
var icons_USDA_inactive = imgBrand('typesImg/Icons_USDA_inactive.png');
var icons_Reverse_inactive = imgBrand('typesImg/Icons_ReverseMortgage_inactive.png');

import { specialtyLoanIcons } from '../../lib/gtm';

// bgimages
import cinemaImage from './images/bground.jpg';
import hero from './images/hero.jpg';

const SpecialtyLoans = ({
   items = [0, 1, 2],
   images = [icons_Jumbo_active, icons_USDA_active, icons_Reverse_active],
   blueImages = [icons_Jumbo_inactive, icons_USDA_inactive, icons_Reverse_inactive],

   captions = ['Jumbo Loans', 'USDA Loans', 'Reverse Mortgage'],

   infos = [
      'Jumbo loans are a special financing option for loan amounts exceeding the FHFA’s conventional conforming loan limit of $510,400 ($765,600 for AK and HI). Perfect for people looking to purchase their dream home.',
      'USDA home loans, also known as USDA Rural Development Guaranteed Housing Loans, are mortgage loans offered to property owners within specified rural areas of the United States.',
      'A reverse mortgage allows you to convert a portion of the equity in your home into cash. Meaning, the equity you’ve built up over the years of making mortgage payments can now be paid back to you.'
   ],
   links = ['/jumbo-loan', '/usda-home-loan', '/reverse-mortgage'],
   alt = [
      'Jumbo Loan Icon - Specialty Loans',
      'USDA Loan Icon - Specialty Loans',
      'Reverse Mortgage Icon - Specialty Loans'
   ].map(altText),
   staticTextItems
}) => {
   const data = useStaticQuery(graphql`
      query SpecialtyLoansPageQuery {
         corpBlogs: allBlog(
            sort: { fields: [date], order: DESC }
            filter: { type: { eq: "corporate" }, tags: { in: ["Specialty Loans"] } }
         ) {
            edges {
               node {
                  id
                  html
                  slug
                  title
                  date
                  feature
                  categories
                  image
                  author {
                     slug
                     profile {
                        name
                     }
                     gallery {
                        photo
                     }
                  }
                  date
                  feature
               }
            }
         }
      }
   `);
   const intro = 'What are specialty loans?';
   const { corpBlogs } = data;
   const { edges: corpBlobPosts = [] } = corpBlogs || {};
   const corporateBlogs = mapBlogs(corpBlobPosts);

   const typesProps = {
      items,
      images,
      blueImages,
      links,
      captions,
      infos,
      title: <span>Know the types of specialty&nbsp;loans</span>,
      gtm: specialtyLoanIcons,
      alt,
      staticTextItems
   };

   const header = <Header active={['current-homeowner', 'specialty-loans']} />;

   return (
      <Layout hasHero={true} header={header}>
         <Helmet>
            <title>{altText('Specialty Loans - Home Loans')}</title>
            <meta
               name="description"
               content="Specialty loans are creative mortgage solutions for homebuyers that need unique loan programs for their unique situations. Not sure if you need a specialty loan? Contact us today."
            />
         </Helmet>
         <HeroLarge customPhoto={hero} />
         <IntroBlock header={intro} id="specialty-loans-intro" staticTextItems={staticTextItems} />
         <Types {...typesProps} />
         <BgImage bg={cinemaImage} />
         {isBe && (
            <>
               <FindLO />
               {corporateBlogs.length >= 3 && (
                  <BlogRoll
                     corporateBlogs={corporateBlogs}
                     blogs={corporateBlogs}
                     showBlogs={true}
                     word="our"
                     url="specialty-loans"
                  />
               )}
            </>
         )}
      </Layout>
   );
};
export default SpecialtyLoans;
