import React from 'react';

import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import DragHandleIcon from '@material-ui/icons/DragHandle';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import VisibityIcon from '@material-ui/icons/Visibility';
import VisibityOffIcon from '@material-ui/icons/VisibilityOff';

import MoveUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MoveDownIcon from '@material-ui/icons/KeyboardArrowDown';

import Uploader from '../../Uploader';

import { capitalize, isEmpty } from 'lodash';
import { imgBrand } from '../../../../../../lib/brand';

import styles from '../widgets.module.less';

var banner = imgBrand('banner.png');
var house = imgBrand('house.png');
var trophy = imgBrand('trophy.png');

const awardIcons = [
   { img: 'banner', url: banner },
   { img: 'house', url: house },
   { img: 'trophy', url: trophy }
];

const type = 'awards';
const field = 'display';
const name = `${type}.${field}`;
const label = 'Display Awards?';
const id = `entry-module-${name}`;

const Awards = ({ widget, onChange, setField, errors, hasErrors, ...rest }) => {
   const { display, awards = [] } = widget;
   const titleColor = !!hasErrors ? 'error' : 'inherit';

   const handleChangeList = list => {
      setField('awards', list);
   };

   return (
      <Accordion TransitionProps={{ mountOnEnter: true, unmountOnExit: true }} className={styles.Widget}>
         <AccordionSummary classes={{ content: styles.TitleBar }} expandIcon={<ExpandMoreIcon />}>
            <DragHandleIcon data-movable-handle />
            <Typography component="h4" variant="h6" color={titleColor}>
               Awards
            </Typography>
            {display ? (
               <VisibityIcon color="disabled" className={styles.WidgetVisibility} />
            ) : (
               <VisibityOffIcon color="disabled" className={styles.WidgetVisibility} />
            )}
         </AccordionSummary>
         <AccordionDetails>
            <Box>
               <Box>
                  <FormControlLabel
                     label={label}
                     control={
                        <Switch
                           color="primary"
                           id={id}
                           name={field}
                           checked={display}
                           onChange={onChange}
                           inputProps={{
                              'data-label': label
                           }}
                        />
                     }
                  />
               </Box>
               <AwardsList awards={awards} onChangeList={handleChangeList} />
            </Box>
         </AccordionDetails>
      </Accordion>
   );
};

export default Awards;

const AwardsList = ({ awards, onChangeList }) => {
   const getHandleChange = index => e => {
      const { name, value } = e.target;
      awards[index][name] = value;
      onChangeList(awards);
   };

   const addItem = e => {
      e.preventDefault();
      const item = {
         awardDescription: '',
         awardYear: '',
         awardBadge: '',
         awardImage: ''
      };
      awards.push(item);
      onChangeList(awards);
   };

   const getDeleter = index => {
      return e => {
         awards.splice(index, 1);
         onChangeList(awards);
      };
   };

   const getMoveUp = index => {
      return e => {
         e.preventDefault();
         const to = Math.max(index - 1, 0);
         awards.splice(to, 0, awards.splice(index, 1)[0]);
         onChangeList(awards);
      };
   };

   const getMoveDown = index => {
      return e => {
         e.preventDefault();
         const to = Math.min(index + 1, awards.length - 1);
         awards.splice(to, 0, awards.splice(index, 1)[0]);
         onChangeList(awards);
      };
   };

   const getFileHandle = index => {
      return (name, cdnUrl) => {
         awards[index][name] = cdnUrl;
         onChangeList(awards);
      };
   };

   return (
      <Box>
         {!isEmpty(awards) &&
            awards.map((award, i) => {
               return (
                  <Paper key={i}>
                     <Box p={2} m={2}>
                        <AwardListItem
                           award={award}
                           onChange={getHandleChange(i)}
                           onFile={getFileHandle(i)}
                           onDelete={getDeleter(i)}
                           onMoveUp={getMoveUp(i)}
                           onMoveDown={getMoveDown(i)}
                        />
                     </Box>
                  </Paper>
               );
            })}
         <Box mt={2}>
            <Button onClick={addItem}>
               <AddIcon />
               Add Award
            </Button>
         </Box>
      </Box>
   );
};

const AwardListItem = ({ award, onChange, onFile, onDelete, onMoveUp, onMoveDown }) => {
   const { awardDescription, awardYear, awardBadge, awardImage } = award;
   return (
      <Grid container direction="row" spacing={2}>
         <Grid item xs={1}>
            <IconButton onClick={onMoveUp}>
               <MoveUpIcon />
            </IconButton>
            <IconButton onClick={onMoveDown}>
               <MoveDownIcon />
            </IconButton>
         </Grid>
         <Grid item xs={4}>
            <TextField
               fullWidth={true}
               name="awardDescription"
               label="Award Description"
               value={awardDescription}
               onChange={onChange}
               helperText="What was this award for? Why is it special?"
               margin="dense"
               inputProps={{
                  'data-lpignore': 'true',
                  'data-label': 'Award Description'
               }}
            />

            <TextField
               fullWidth={true}
               name="awardYear"
               label="Award Year"
               value={awardYear}
               onChange={onChange}
               helperText="In which year did you receive this award?"
               margin="dense"
               inputProps={{
                  'data-lpignore': 'true',
                  'data-label': 'Award Year'
               }}
            />
            <TextField
               name="awardBadge"
               select
               disabled={!!awardImage}
               label="Award Badge"
               value={awardBadge || 'banner'}
               onChange={onChange}
               margin="dense"
               helperText="Select an icon to represent your award">
               {awardIcons.map(icon => {
                  const { img, url } = icon;
                  return (
                     <MenuItem key={img} value={img}>
                        <div className={styles.BadgeListItem}>
                           <ListItemIcon>
                              <Avatar src={url} />
                           </ListItemIcon>
                           <Typography component="span" variant="body1">
                              {capitalize(img)}
                           </Typography>
                        </div>
                     </MenuItem>
                  );
               })}
            </TextField>
         </Grid>
         <Grid item>
            <Uploader
               name="awardImage"
               label="Award Image"
               entry={award}
               value={awardImage}
               handleFile={onFile}
               hint="Upload a logo or graphic representing your award."
               config={{
                  imagesOnly: true,
                  crop: '1:1'
                  // imageShrink: "200x200", // this seems to make UploadCare unhappy.
               }}
            />
         </Grid>
         <Grid item container className={styles.AwardDelete} xs justify="flex-end" alignItems="flex-start">
            <IconButton onClick={onDelete}>
               <DeleteIcon />
            </IconButton>
         </Grid>
      </Grid>
   );
};
