import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { mapBlogs } from '../../lib/utility';
import BlogRoll from '../../components/BlogRoll';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import ProductHero from '../../components/ProductHero';
import FindLO from '../../components/FindLO';
import Calculators from '../../components/Calculators';
import ProductDetails from '../../components/ProductDetails';
import StaticText from '../../components/StaticText';
import { img, imgBrand, alt, isBe } from '../../lib/brand';
import styles from './styles.module.less';

var background = img('jumboLoansHeroBg.jpg');
var icon = imgBrand('typesImg/Icons_Jumbo_active.png');

const Jumbo = ({ staticTextItems }) => {
   const data = useStaticQuery(graphql`
      query JumboQuery {
         corpBlogs: allBlog(
            sort: { fields: [date], order: DESC }
            filter: { type: { eq: "corporate" }, tags: { in: ["Jumbo Loans"] } }
         ) {
            edges {
               node {
                  id
                  html
                  slug
                  title
                  date
                  feature
                  categories
                  tags
                  image
                  author {
                     slug
                     profile {
                        name
                     }
                     gallery {
                        photo
                     }
                  }
                  date
                  feature
               }
            }
         }
      }
   `);
   const header = <Header active={['jumbo-loan']} />;
   const intro = 'Jumbo Loans';
   const subhead = <StaticText id="jumbo-loan-header" items={staticTextItems} />;
   const benefits = 'What are the benefits of a jumbo loan?';
   const qualify = 'How do I qualify for a jumbo loan?';
   const { corpBlogs } = data;
   const { edges: corpBlobPosts = [] } = corpBlogs || {};
   const corporateBlogs = mapBlogs(corpBlobPosts);

   return (
      <Layout hasHero={true} header={header}>
         <Helmet>
            <title>{alt('Jumbo Home Loan')}</title>
            <meta
               name="description"
               content="Jumbo loans are a special financing option for loan amounts exceeding the FHFA’s conventional conforming loan limit. Need a jumbo home loan? Contact us today."
            />
            <script
               src={`https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}`}></script>
         </Helmet>
         <ProductHero header={intro} customPhoto={background} icon={icon} subhead={subhead} alt={alt('Jumbo Loan')} />
         <ProductDetails title={'What is a jumbo loan?'} border={true}>
            <div className={styles.detailsItem}>
               <StaticText id="jumbo-loan-what-is" items={staticTextItems} />
            </div>
         </ProductDetails>
         <ProductDetails title={benefits} border={true}>
            <div className={styles.detailsItem}>
               <StaticText id="jumbo-loan-benefits" items={staticTextItems} />
            </div>
         </ProductDetails>
         <ProductDetails title={'What are the current jumbo loan rates?'} border={true}>
            <div className={styles.detailsItem}>
               <StaticText id="jumbo-loan-current-rates" items={staticTextItems} />
            </div>
         </ProductDetails>
         <ProductDetails title={qualify}>
            <div className={styles.detailsItem}>
               <StaticText id="jumbo-loan-qualify" items={staticTextItems} />
            </div>
         </ProductDetails>
         {isBe && (
            <>
               <Calculators
                  showCalculatorMortgage={true}
                  showCalculatorRentVsBuy={true}
                  showCalculatorHomeAffordability={true}
                  showCalculatorRequiredIncome={true}
               />
               <FindLO />
               {corporateBlogs.length >= 3 && (
                  <BlogRoll
                     corporateBlogs={corporateBlogs}
                     blogs={corporateBlogs}
                     showBlogs={true}
                     word="our"
                     url="jumbo-loans"
                  />
               )}
            </>
         )}
      </Layout>
   );
};

export default Jumbo;
