import React from 'react';
import { List, arrayMove } from 'react-movable';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import Uploader from '../Uploader';

import styles from './styles.module.less';
import { brandLogo200 } from '../../../../../lib/brand';

const defaultStaff = {
   name: '',
   photo: brandLogo200,
   title: '',
   license: '',
   email: ''
};

const Staff = ({ field = 'staff', label = 'Staff Members', values, handleListChange }) => {
   const getHandler = index => {
      return e => {
         const { name, value } = e.target;
         values[index][name] = value;
         handleListChange(field, values);
      };
   };

   const getFileHandler = index => {
      return (name, cdnUrl) => {
         values[index][name] = cdnUrl;
         handleListChange(field, values);
      };
   };

   const getDeleter = index => {
      return e => {
         values.splice(index, 1);
         handleListChange(field, values);
      };
   };

   const add = () => {
      values.push(Object.assign({}, defaultStaff));
      handleListChange(field, values);
   };

   return (
      <Paper className={styles.Staff} elevation={1}>
         <Box padding={2} margin={2}>
            <Typography component="h3" variant="h6">
               {label}
            </Typography>
            <List
               values={values}
               onChange={({ oldIndex, newIndex }) => {
                  handleListChange(field, arrayMove(values, oldIndex, newIndex));
               }}
               renderList={({ children, props, isDragged }) => {
                  return (
                     <div
                        {...props}
                        style={{
                           cursor: isDragged ? 'grabbing' : 'inherit'
                        }}>
                        {children}
                     </div>
                  );
               }}
               renderItem={({ value, props, isDragged, isSelected, index }) => {
                  // disable keyboard navigation on widgets
                  props.onKeyDown = e => {
                     return e;
                  };
                  const handleChange = getHandler(index);
                  const handleFile = getFileHandler(index);
                  const handleDelete = getDeleter(index);
                  return (
                     <div {...props}>
                        <Row
                           key={index}
                           isDragged={isDragged}
                           row={value}
                           handleChange={handleChange}
                           handleFile={handleFile}
                           handleDelete={handleDelete}
                        />
                     </div>
                  );
               }}
            />
            <Box className={styles.add} marginTop={2}>
               <IconButton onClick={add} margin={0}>
                  <AddIcon />
               </IconButton>
            </Box>
         </Box>
      </Paper>
   );
};

export default Staff;

const Row = ({ row, isDragged, handleChange, handleFile, handleDelete }) => {
   const { photo, name, title, license, email } = row;
   const cls = isDragged ? `${styles.row} ${styles.isDragged}` : styles.row;
   return (
      <Box display="flex" alignItems="center" className={cls}>
         <Box>
            <DragHandleIcon data-movable-handle />
         </Box>
         <Box display="flex" alignItems="flex-end">
            <Box padding={1}>
               <Uploader
                  name="photo"
                  label="Photo"
                  slim
                  value={photo}
                  defaultValue={defaultStaff.photo}
                  config={{
                     imagesOnly: true,
                     crop: '1:1',
                     imageShrink: '500x500'
                  }}
                  handleFile={handleFile}
               />
               <TextField
                  name="name"
                  label="Full Name"
                  value={name}
                  onChange={handleChange}
                  margin="normal"
                  inputProps={{
                     'data-lpignore': 'true',
                     'data-label': 'Staff member full name'
                  }}
               />
               <TextField
                  name="title"
                  label="Title"
                  value={title}
                  onChange={handleChange}
                  margin="normal"
                  inputProps={{
                     'data-lpignore': 'true',
                     'data-label': 'Staff member job title'
                  }}
               />
            </Box>

            <Box padding={1}>
               <TextField
                  name="email"
                  label="Email"
                  value={email}
                  margin="normal"
                  onChange={handleChange}
                  inputProps={{
                     'data-lpignore': 'true',
                     'data-label': 'Staff member email'
                  }}
               />
               <TextField
                  name="license"
                  label="License #"
                  value={license}
                  margin="normal"
                  onChange={handleChange}
                  inputProps={{
                     'data-lpignore': 'true',
                     'data-label': 'Staff member license #'
                  }}
               />
            </Box>
         </Box>
         <IconButton onClick={handleDelete}>
            <DeleteIcon />
         </IconButton>
      </Box>
   );
};
