import React, { useState, useEffect } from 'react';
import Frame from 'react-frame-component';
import SplitPane from 'react-split-pane';
import { noop, isEmpty } from 'lodash';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Typography from '@material-ui/core/Typography';
import { brandLogo200 } from '../../../../../lib/brand';

// intro
import Title from '../Title';
import Owner from '../Owner';
import UrlSlug from '../UrlSlug';
import ApplyNow from '../ApplyNow';
import LogIn from '../LogIn';
import HidePage from '../HidePage';
import Terminated from '../Terminated';
import Disclaimer from '../Disclaimer';

// hero
import HeroImage from '../HeroImage';
import HeroQuote from '../HeroQuote';
import HeroByline from '../HeroByline';
import PhotoAlignment from '../PhotoAlignment';

// Profile
import FullName from '../FullName';
import ProfileEmail from '../ProfileEmail';
import JobTitle from '../JobTitle';
import BranchSelect from '../BranchSelect';
import Licenses from '../Licenses';
import LicenseLink from '../LicenseLink';

import Uploader from '../Uploader';

// Contact & Social
import Facebook from '../Facebook';
import LinkedIn from '../LinkedIn';
import YouTube from '../YouTube';
import Instagram from '../Instagram';
import Twitter from '../Twitter';
import Telephone from '../Telephone';

// Search Visibility
import SearchVisibilitySelect from '../SearchVisibilitySelect';

// widgets!
import Widgets from '../Widgets';

// change log
import ChangeLog from '../ChangeLog';

// scripts
import CustomScript from '../CustomScript';

// preview view
import LoanOfficerPreview from '../LoanOfficerPreview';

import { heroPhotoAlignment } from '../../../lib/defaults';

import styles from './styles.module.less';
import './styles.less';

/**
 * Collect style elements from the current page for injection into preview iframe
 */
const getStyles = () => {
   let head = '';
   const sheets = Array.from(document.querySelectorAll('link[rel=stylesheet]'));
   const styles = Array.from(document.querySelectorAll('head style'));

   sheets.forEach(link => {
      head += link.outerHTML;
   });

   styles.forEach(style => {
      head += style.outerHTML;
   });

   return head;
};

const LoanOfficer = ({
   loaded = false,
   entry,
   errors,
   missing,
   isAdmin,
   email,
   handleChange = noop,
   handleListChange = noop,
   handleFile = noop,
   handleRef = noop,
   handleTerminate = noop,
   fetchCollection = noop
}) => {
   // container for preview frame styles
   const [previewStyles, setPreviewStyles] = useState(null);
   // enables / disables pointer events on editor and preview during resizing
   const [blockInput, setBlockInput] = useState(false);

   useEffect(() => {
      if (!previewStyles) {
         setPreviewStyles(getStyles());
      }
   }, [previewStyles]);

   const onDragStarted = () => {
      setBlockInput(true);
   };

   const onDragFinished = () => {
      setBlockInput(false);
   };

   const hasErrorsInSection = (errors, section) => {
      let keys = Object.keys(errors);
      return keys.some(key => key.startsWith(section) && !!errors[key]);
   };

   let profileErrorColor = hasErrorsInSection(errors, 'profile') ? 'error' : 'inherit';
   let heroErrorColor = hasErrorsInSection(errors, 'hero') ? 'error' : 'inherit';
   let galleryErrorColor = hasErrorsInSection(errors, 'gallery') ? 'error' : 'inherit';
   let contactErrorColor = hasErrorsInSection(errors, 'contact') ? 'error' : 'inherit';
   let searchVisibilityErrorColor = hasErrorsInSection(errors, 'searchVisibility') ? 'error' : 'inherit';

   let loClassName = !!blockInput ? `${styles.LoanOfficer} ${styles.blockEntry}` : styles.LoanOfficer;
   let frmClassName = !!blockInput ? `${styles.previewFrame} ${styles.blockEntry}` : styles.previewFrame;

   // don't bother if we don't have an entry to display
   if (!loaded) return null;
   if (isEmpty(entry)) return null;
   if (!entry.searchVisibility) entry.searchVisibility = [];

   return (
      <SplitPane
         split="vertical"
         minSize={320}
         defaultSize="50%"
         style={{
            height: 'calc(100% - 64px)'
         }}
         onDragStarted={onDragStarted}
         onDragFinished={onDragFinished}>
         <div className={loClassName}>
            <Title isAdmin={isAdmin} onChange={handleChange} value={entry.title} errors={errors} />
            <Owner isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} defaultValue={email} />
            <UrlSlug isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
            <Paper elevation={1}>
               <Box padding={2} margin={2}>
                  <Typography color="textPrimary" component="h3" variant="h6">
                     Loan application tools and links
                  </Typography>
                  <Typography component="p" variant="body2" gutterBottom>
                     For users of Floify, Consumer Connect, or Encompass application tools, enter the appropriate URL
                     under <strong>Apply Now / Log In Url</strong>.
                  </Typography>
                  <Typography component="p" variant="body2" align="center" gutterBottom>
                     <strong>
                        <em>or</em>
                     </strong>
                  </Typography>
                  <Typography component="p" variant="body2" gutterBottom>
                     For users of the embedded application widget, paste the embed script under{' '}
                     <strong>Embedded Application Widget</strong>.
                  </Typography>
                  <Typography component="em" variant="caption">
                     If both a URL and embed script are provided, only the script will be used.
                  </Typography>
                  <ApplyNow isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
                  <CustomScript
                     name="applyNowScript"
                     label="Embedded Application Widget (script)"
                     hint="Enter loan application widget code snippet. Code snippet should be complete including <script> and <div> tags."
                     isAdmin={isAdmin}
                     onChange={handleChange}
                     entry={entry}
                     errors={errors}
                  />
               </Box>
            </Paper>

            <LogIn isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
            <HidePage isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
            <Terminated isAdmin={isAdmin} onChange={handleTerminate} entry={entry} errors={errors} />

            <Paper elevation={1}>
               <Box padding={2} margin={2}>
                  <Disclaimer isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
               </Box>
            </Paper>

            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="h3" variant="h6" color={profileErrorColor}>
                     Profile
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Box padding={2} margin={2}>
                     <FullName isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
                     <ProfileEmail isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
                     <JobTitle
                        isAdmin={isAdmin}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                        defaultValue="Loan Officer"
                     />
                     <BranchSelect isAdmin={isAdmin} onRefSelect={handleRef} entry={entry} errors={errors} />
                     <Licenses isAdmin={isAdmin} handleListChange={handleListChange} entry={entry} errors={errors} />
                     <LicenseLink isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
                  </Box>
               </AccordionDetails>
            </Accordion>

            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="h3" variant="h6" color={heroErrorColor}>
                     Page header
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Box padding={2} margin={2}>
                     <HeroImage isAdmin={isAdmin} value={entry.hero.heroBackground} onChange={handleChange} />

                     <Uploader
                        name="hero.customPhoto"
                        label="Custom Photo"
                        hint="Upload your custom header photo (images must be 2881x2017, in jpg format)"
                        entry={entry}
                        config={{
                           imagesOnly: true,
                           inputAcceptTypes: '.jpg, .jpeg',
                           crop: '16:9',
                           imageShrink: '2881x2017'
                        }}
                        handleFile={handleFile}
                     />
                     <PhotoAlignment
                        name="hero.photoAlignment"
                        value={entry.hero.photoAlignment}
                        onChange={handleChange}
                        defaultValue={heroPhotoAlignment}
                        entry={entry}
                        errors={errors}
                     />
                     <HeroQuote isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
                     <HeroByline isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
                  </Box>
               </AccordionDetails>
            </Accordion>

            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="h3" variant="h6" color={galleryErrorColor}>
                     Gallery
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Box padding={2} margin={2}>
                     <Typography component="p" variant="body2">
                        Upload your headshot and up to three other photos
                     </Typography>
                     <Box margin={1} className={styles.gallery}>
                        <Uploader
                           name="gallery.photo"
                           label="Headshot"
                           entry={entry}
                           config={{
                              imagesOnly: true,
                              crop: '1:1',
                              imageShrink: '500x500'
                           }}
                           defaultValue={brandLogo200}
                           handleFile={handleFile}
                        />

                        <Uploader
                           name="gallery.image1"
                           label="Gallery Photo 1"
                           entry={entry}
                           config={{
                              imagesOnly: true,
                              crop: '1:1',
                              imageShrink: '500x500'
                           }}
                           handleFile={handleFile}
                        />

                        <Uploader
                           name="gallery.image2"
                           label="Gallery Photo 2"
                           entry={entry}
                           config={{
                              imagesOnly: true,
                              crop: '1:1',
                              imageShrink: '500x500'
                           }}
                           handleFile={handleFile}
                        />

                        <Uploader
                           name="gallery.image3"
                           label="Gallery Photo 3"
                           entry={entry}
                           config={{
                              imagesOnly: true,
                              crop: '1:1',
                              imageShrink: '500x500'
                           }}
                           handleFile={handleFile}
                        />
                     </Box>
                  </Box>
               </AccordionDetails>
            </Accordion>

            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="h3" variant="h6" color={contactErrorColor}>
                     {`Contact & Social`}
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Box padding={2} margin={2}>
                     <Typography component="p" variant="body2">
                        Let your customers know how to find you
                     </Typography>
                     <Facebook isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
                     <LinkedIn isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
                     <YouTube isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
                     <Instagram isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
                     <Twitter isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
                     <Telephone
                        name="contact.phone"
                        label="Telephone"
                        isAdmin={isAdmin}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                     <Telephone
                        name="contact.mobilePhone"
                        label="Mobile Phone"
                        hint="Format 555-555-5555"
                        isAdmin={isAdmin}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                     <Telephone
                        name="contact.fax"
                        label="Fax"
                        hint="Format 555-555-5555"
                        isAdmin={isAdmin}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                  </Box>
               </AccordionDetails>
            </Accordion>

            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="h3" variant="h6" color={searchVisibilityErrorColor}>
                     {`Search Visibility`}
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Box padding={2} margin={2}>
                     <Typography component="p" variant="body2">
                        Select each of the states that the loan officer is licensed in to override search results.
                     </Typography>
                     <SearchVisibilitySelect
                        isAdmin={isAdmin}
                        onRefSelect={handleRef}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                  </Box>
               </AccordionDetails>
            </Accordion>

            <Box mt={2}>
               <Widgets
                  entry={entry}
                  errors={errors}
                  missing={missing}
                  handleListChange={handleListChange}
                  isAdmin={isAdmin}
                  pageType="loanOfficer"
               />
            </Box>

            <ChangeLog isAdmin={isAdmin} onChange={handleChange} value={entry.changelog} />

            <CustomScript
               name="headBeginScript"
               label="HEAD Beginning Script"
               isAdmin={isAdmin}
               onChange={handleChange}
               entry={entry}
               errors={errors}
            />
            <CustomScript
               name="headEndScript"
               label="HEAD Ending Script"
               isAdmin={isAdmin}
               onChange={handleChange}
               entry={entry}
               errors={errors}
            />
            <CustomScript
               name="bodyBeginScript"
               label="BODY Beginning Script"
               isAdmin={isAdmin}
               onChange={handleChange}
               entry={entry}
               errors={errors}
            />
            <CustomScript
               name="bodyEndScript"
               label="BODY Ending Script"
               isAdmin={isAdmin}
               onChange={handleChange}
               entry={entry}
               errors={errors}
            />
         </div>
         <Frame
            frameBorder="none"
            className={frmClassName}
            head={
               <>
                  <style>{'html, body {background-color: #F6F6F6; margin: 0; padding: 0;}'}</style>
                  <base target="_blank" />
               </>
            }
            // #6973: Had to add Uploadcare's AD script directly in order to get it to process images in the iFrame.
            initialContent={`<!DOCTYPE html><html><head>${previewStyles}</head><body><div class="frame-root">
                    <script>
                        (function(src, cb) {
                            var s = document.createElement("script");
                            s.setAttribute("src", src);
                            s.onload = cb;
                            (document.head || document.body).appendChild(s);
                        })("https://ucarecdn.com/libs/blinkloader/3.x/blinkloader.min.js", function() {
                            window.Blinkloader.optimize({
                                pubkey: "${process.env.GATSBY_UPLOADCARE_PUBLIC_KEY}",
                                fadeIn: true,
                                lazyload: true,
                                smartCompression: true,
                                responsive: true,
                                retina: false,
                                webp: true,
                            });
                        });
                    </script>
                    <script src="https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}"></script></div></body></html>`}>
            <LoanOfficerPreview entry={entry} fetchCollection={fetchCollection} />
         </Frame>
      </SplitPane>
   );
};

export default LoanOfficer;
