import React from "react";
import { HTMLContent } from "../../../../components/Content";
import styles from "./styles.module.less";

const Message = ({ html, messageFrom, companyPhoto }) => {
    return (
        <div className={styles.sectionContainer}>
            <div className={styles.containerFluid}>
                <div className={styles.row}>
                    <div className={styles.outerContainer}>
                        <div className={styles.messageContainer}>
                            <div className={styles.sectionHeader}>
                                {messageFrom}
                            </div>
                            <HTMLContent content={html} />
                        </div>
                        <div className={styles.messageImageContainer}>
                            <img
                                className={styles.messageImage}
                                alt=""
                                data-blink-src={companyPhoto}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Message;
