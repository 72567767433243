import React, { useState, useEffect } from 'react';
import { noop } from 'lodash';
import { navigate } from 'gatsby';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Loader from '../components/Loader';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';

import Save from '@material-ui/icons/SaveAlt';
import Publish from '@material-ui/icons/Publish';
import ArrowBack from '@material-ui/icons/ArrowBack';

import HidePage from '../Entry/components/HidePage';

import { Typography } from '@material-ui/core';

import styles from './styles.module.less';

const stylesExt = theme => ({
   button: {
      margin: theme.spacing(1)
   }
});

const StaticPage = ({
   publishSite = noop,
   fetchStaticPageItems = noop,
   batchStaticPageItems = noop,
   isAdmin = noop
}) => {
   const [staticPageItems, setStaticPageItems] = useState([]);
   const [loaded, setLoaded] = useState(false);
   const [dirty, setDirty] = useState(false);
   const [showNotSaved, setShowNotSaved] = useState(false);

   useEffect(() => {
      async function fetchData() {
         if (!isAdmin) {
            eject();
         }
         await fetchItems();
      }
      fetchData();
   }, []);

   const fetchItems = async () => {
      let staticPage = await fetchStaticPageItems();
      setStaticPageItems(staticPage);
      setLoaded(true);
      setDirty(false);
   };

   /**
    * save and publish
    */
   const handlePublish = async e => {
      // do work
      await batchStaticPageItems(staticPageItems);
      // publish site
      await publishSite();
      eject();
   };

   const handleSave = async e => {
      setLoaded(false);
      // do work
      await batchStaticPageItems(staticPageItems);
      // reload
      await fetchItems();
   };

   const eject = () => {
      navigate(`/admin/`);
   };

   const handleNavigateBack = e => {
      if (!!dirty) {
         setShowNotSaved(true);
         return;
      }
      eject();
   };

   const handleNavigateBackWithoutSaving = e => {
      setShowNotSaved(false);
      eject();
   };

   const DiscardChangesDialog = ({ open, onCancel, onContinue }) => {
      return (
         <Dialog open={open} onClose={onCancel}>
            <DialogTitle>Discard changes?</DialogTitle>
            <DialogContent>
               <DialogContentText>
                  There are unsaved changes.
                  <br />
                  Click "No" to continue working and save changes.
                  <br />
                  Click "Yes" to discard and return to the list.
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button onClick={onCancel}>No, continue working</Button>
               <Button onClick={onContinue}>Yes, discard changes</Button>
            </DialogActions>
         </Dialog>
      );
   };

   const dirtyColor = !!dirty ? 'secondary' : 'inherit';

   // don't bother if we don't have an entry to display
   if (!loaded) return null;

   return (
      <div className={styles.StaticPage}>
         <DiscardChangesDialog
            open={showNotSaved}
            onCancel={e => setShowNotSaved(false)}
            onContinue={handleNavigateBackWithoutSaving}
         />
         <AppBar position="sticky" color="default">
            <Toolbar>
               <Button color="inherit" onClick={handleNavigateBack}>
                  <ArrowBack />
                  Back
               </Button>
               <Typography component="span" variant="h6" color={dirtyColor} className={styles.Title}>
                  Site Pages
               </Typography>

               <Button color={dirtyColor} variant="contained" onClick={handleSave} className={styles.ToolbarButton}>
                  Save
                  <Save />
               </Button>

               <Button color="default" variant="contained" onClick={handlePublish} className={styles.ToolbarButton}>
                  Publish
                  <Publish />
               </Button>
            </Toolbar>
         </AppBar>
         <Grid container spacing={0} component="div" justify="center">
            <Grid item xs={8}>
               <Loader loading={!loaded} />
               <Box hidden={!loaded} margin={1} padding={1}>
                  <Typography component="h1" variant="h6" color={dirtyColor}>
                     Site Pages
                  </Typography>
                  <Typography component="p" variant="body1" color={dirtyColor}>
                     Manage site pages below.
                     <br />
                     Publishing will apply changes to the site.
                  </Typography>
               </Box>

               <Box style={{ background: 'white' }}>
                  <StaticPageList
                     loaded={loaded}
                     staticPageItems={staticPageItems}
                     setStaticPageItems={setStaticPageItems}
                     setDirty={setDirty}
                  />
               </Box>
            </Grid>
         </Grid>
      </div>
   );
};

const StaticPageList = ({ loaded, staticPageItems, setStaticPageItems, setDirty }) => {
   return (
      loaded &&
      staticPageItems.map((item, index) => {
         const getStaticPageChangeHandler = e => {
            staticPageItems[index].hidden = e.target.checked;
            staticPageItems[index].dirty = true;
            console.log('onChange - item', staticPageItems[index]);
            setStaticPageItems([].concat(staticPageItems));
            setDirty(true);
         };

         // don't show the admin or upload pages
         if (['admin', 'upload'].includes(item.id)) return;

         return (
            <div>
               <StaticPageItem key={item.id} item={item} onChange={getStaticPageChangeHandler} />
            </div>
         );
      })
   );
};

const StaticPageItem = ({ item, onChange }) => {
   const { title, dirty = false } = item;
   const dirtyColor = !!dirty ? 'secondary' : 'inherit';
   return (
      <>
         <Box className={styles.StaticPageItem}>
            <Typography component="h1" variant="h6" color={dirtyColor}>
               {title}
            </Typography>
            <Box>
               <HidePage onChange={onChange} entry={item} errors={{ hidden: '' }} hint="" />
            </Box>
         </Box>
      </>
   );
};

export default withStyles(stylesExt)(StaticPage);
