import React, { useState, useEffect } from 'react';
import Frame from 'react-frame-component';
import SplitPane from 'react-split-pane';
import { noop, isEmpty, set } from 'lodash';
import { geocode } from '../../../../../lib/geo';
import { brandLogo200 } from '../../../../../lib/brand';

import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

// Profile
import FullName from '../FullName';
import ProfileEmail from '../ProfileEmail';
import JobTitle from '../JobTitle';
import Licenses from '../Licenses';

import Uploader from '../Uploader';
import Number from '../Number';

// Contact & Social
import Facebook from '../Facebook';
import LinkedIn from '../LinkedIn';
import YouTube from '../YouTube';
import Instagram from '../Instagram';
import Twitter from '../Twitter';
import Telephone from '../Telephone';
import AddressLine from '../AddressLine';
import City from '../City';
import State from '../State';
import ZipCode from '../Zip';

// change log
import ChangeLog from '../ChangeLog';

// preview view
import NonOriginatorPreview from '../NonOriginatorPreview';

import styles from './styles.module.less';
import './styles.less';

/**
 * Collect style elements from the current page for injection into preview iframe
 */
const getStyles = () => {
   let head = '';
   const sheets = Array.from(document.querySelectorAll('link[rel=stylesheet]'));
   const styles = Array.from(document.querySelectorAll('head style'));

   sheets.forEach(link => {
      head += link.outerHTML;
   });

   styles.forEach(style => {
      head += style.outerHTML;
   });

   return head;
};

const NonOriginator = ({
   loaded = false,
   entry,
   errors,
   missing,
   isAdmin,
   email,
   handleChange = noop,
   handleListChange = noop,
   handleFile = noop,
   handleRef = noop,
   handleBodyChange = noop,
   handleTerminate = noop,
   handleBlur = noop,
   fetchCollection = noop
}) => {
   // container for preview frame styles
   const [previewStyles, setPreviewStyles] = useState(null);
   // enables / disables pointer events on editor and preview during resizing
   const [blockInput, setBlockInput] = useState(false);

   useEffect(() => {
      if (!previewStyles) {
         setPreviewStyles(getStyles());
      }
   }, [previewStyles]);

   const onDragStarted = () => {
      setBlockInput(true);
   };

   const onDragFinished = () => {
      setBlockInput(false);
   };

   const handleAddressChange = e => {
      handleChange(e);
      const { address1, address2, address3, city, state, zip } = entry.profile.branch;
      // do not geocode incomplete address
      if (!address1 || !city || !state || !zip) return;
      let address = `${address1} ${address2} ${address3} ${city}, ${state} ${zip}`;
      geocode({ address }).then(result => {
         if (result) {
            const { lat, lon } = result;
            // simulate events
            let eLat = {
               target: {
                  name: 'profile.branch.lat',
                  value: parseFloat(lat),
                  type: 'text'
               }
            };
            let eLon = {
               target: {
                  name: 'profile.branch.lon',
                  value: parseFloat(lon),
                  type: 'text'
               }
            };
            // set value
            handleChange(eLat);
            handleChange(eLon);
            // trigger validation
            handleBlur(eLat);
            handleBlur(eLon);
         }
      });
   };

   const hasErrorsInSection = (errors, section) => {
      let keys = Object.keys(errors);
      return keys.some(key => key.startsWith(section) && !!errors[key]);
   };

   let profileErrorColor = hasErrorsInSection(errors, 'profile') ? 'error' : 'inherit';
   let galleryErrorColor = hasErrorsInSection(errors, 'gallery') ? 'error' : 'inherit';
   let contactErrorColor = hasErrorsInSection(errors, 'contact') ? 'error' : 'inherit';

   let loClassName = !!blockInput ? `${styles.NonOriginator} ${styles.blockEntry}` : styles.NonOriginator;
   let frmClassName = !!blockInput ? `${styles.previewFrame} ${styles.blockEntry}` : styles.previewFrame;

   const handleNameChange = e => {
      set(entry, 'title', e.target.value);
      handleChange(e);
   };

   // don't bother if we don't have an entry to display
   if (!loaded) return null;
   if (isEmpty(entry)) return null;

   return (
      <SplitPane
         split="vertical"
         minSize={320}
         defaultSize="50%"
         style={{
            height: 'calc(100% - 64px)'
         }}
         onDragStarted={onDragStarted}
         onDragFinished={onDragFinished}>
         <div className={loClassName}>
            <Accordion defaultExpanded={true}>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="h3" variant="h6" color={profileErrorColor}>
                     Profile
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Box padding={2} margin={2}>
                     <FullName isAdmin={isAdmin} onChange={handleNameChange} entry={entry} errors={errors} />
                     <ProfileEmail isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
                     <JobTitle
                        isAdmin={isAdmin}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                        defaultValue="Loan Officer"
                     />
                     <Licenses isAdmin={isAdmin} handleListChange={handleListChange} entry={entry} errors={errors} />
                     <AddressLine
                        name="profile.branch.address1"
                        label="Address1"
                        onChange={handleAddressChange}
                        entry={entry}
                        errors={errors}
                     />
                     <AddressLine
                        name="profile.branch.address2"
                        label="Address2"
                        onChange={handleAddressChange}
                        entry={entry}
                        errors={errors}
                     />
                     <AddressLine
                        name="profile.branch.address3"
                        label="Address3"
                        onChange={handleAddressChange}
                        entry={entry}
                        errors={errors}
                     />
                     <City name="profile.branch.city" onChange={handleAddressChange} entry={entry} errors={errors} />
                     <State name="profile.branch.state" onChange={handleAddressChange} entry={entry} errors={errors} />
                     <ZipCode
                        name="profile.branch.zip"
                        onChange={handleAddressChange}
                        onBlur={handleBlur}
                        entry={entry}
                        errors={errors}
                     />
                     <Number
                        name="profile.branch.lat"
                        label="Latitude"
                        step={0.1}
                        onChange={handleChange}
                        entry={entry}
                        value={entry.profile.branch.lat}
                        errors={errors}
                     />
                     <Number
                        name="profile.branch.lon"
                        value={entry.profile.branch.lon}
                        label="longitude"
                        step={0.1}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                  </Box>
               </AccordionDetails>
            </Accordion>

            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="h3" variant="h6" color={galleryErrorColor}>
                     Gallery
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Box padding={2} margin={2}>
                     <Typography component="p" variant="body2">
                        Upload your headshot and up to three other photos
                     </Typography>
                     <Box margin={1} className={styles.gallery}>
                        <Uploader
                           name="gallery.photo"
                           label="Headshot"
                           entry={entry}
                           config={{
                              imagesOnly: true,
                              crop: '1:1',
                              imageShrink: '500x500'
                           }}
                           defaultValue={brandLogo200}
                           handleFile={handleFile}
                        />
                     </Box>
                  </Box>
               </AccordionDetails>
            </Accordion>

            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="h3" variant="h6" color={contactErrorColor}>
                     {`Contact & Social`}
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Box padding={2} margin={2}>
                     <Typography component="p" variant="body2">
                        Let your customers know how to find you
                     </Typography>
                     <Facebook isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
                     <LinkedIn isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
                     <YouTube isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
                     <Instagram isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
                     <Twitter isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
                     <Telephone
                        name="contact.phone"
                        label="Telephone"
                        isAdmin={isAdmin}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                     <Telephone
                        name="contact.mobilePhone"
                        label="Mobile Phone"
                        hint="Format 555-555-5555"
                        isAdmin={isAdmin}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                     <Telephone
                        name="contact.fax"
                        label="Fax"
                        hint="Format 555-555-5555"
                        isAdmin={isAdmin}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                  </Box>
               </AccordionDetails>
            </Accordion>

            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="h3" variant="h6">
                     {`DBA Logo`}
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  {/* DBA Logo is admin only */}
                  {!!isAdmin && (
                     <Box padding={0} margin={2}>
                        <Uploader
                           name="profile.branch.dbaLogoBlack"
                           label=""
                           hint="Optional DBA Logo. For best results use a full-color (e.g. black and orange) landscape formatted PNG image with a transparent background. Image should be cropped tightly around the logo. Alternate versions will be created dynamically."
                           entry={entry}
                           config={{
                              imagesOnly: true,
                              inputAcceptTypes: '.png',
                              effects: 'crop'
                           }}
                           handleFile={handleFile}
                        />
                     </Box>
                  )}
               </AccordionDetails>
            </Accordion>

            <ChangeLog isAdmin={isAdmin} onChange={handleChange} value={entry.changelog} />
         </div>
         <Frame
            frameBorder="none"
            className={frmClassName}
            head={
               <>
                  <style>{'html, body {background-color: #F6F6F6; margin: 0; padding: 0;}'}</style>
                  <base target="_blank" />
               </>
            }
            // #6973: Had to add Uploadcare's AD script directly in order to get it to process images in the iFrame.
            initialContent={`<!DOCTYPE html><html><head>${previewStyles}</head><body><div class="frame-root">
                    <script>
                        (function(src, cb) {
                            var s = document.createElement("script");
                            s.setAttribute("src", src);
                            s.onload = cb;
                            (document.head || document.body).appendChild(s);
                        })("https://ucarecdn.com/libs/blinkloader/3.x/blinkloader.min.js", function() {
                            window.Blinkloader.optimize({
                                pubkey: "${process.env.GATSBY_UPLOADCARE_PUBLIC_KEY}",
                                fadeIn: true,
                                lazyload: true,
                                smartCompression: true,
                                responsive: true,
                                retina: false,
                                webp: true,
                            });
                        });
                    </script></div></body></html>`}>
            <NonOriginatorPreview entry={entry} fetchCollection={fetchCollection} />
         </Frame>
      </SplitPane>
   );
};

export default NonOriginator;
