import React from "react";
import { Helmet } from "react-helmet";

import Layout from "./components/Layout";
import Header from "./components/Header";
import Message from "./components/Message";

import styles from "./styles.module.less";

import ModuleList from "../ModuleList";
import withSurvey from "../withSurvey";

const CommunityCareDetail = props => {
    const {
        firestoreId,
        slug,
        title,
        leadEmailInfo = [],
        companyLogo,
        companyPhoto,
        loanOfficer = null,
        team = null,
        html,
        messageFrom,
        testimonials = [],
        realtor,
        headBeginScript = [],
        headEndScript = [],
        bodyBeginScript = [],
        bodyEndScript = [],
        hero,
        modules = [],
        applyNow,
        applyNowScript,
        contactInfo,
        isPreview,
        blogs,
        corporateBlogs,
        photo,
        testimonials: review = [],
        isSurveyInit: isReviewInit,
        hasSurveyErrored: hasReviewErrored,
        blogWord,
        templateKey,
        owner,
        logIn,
        ownerSlug,
        socialIcons,
        licenseIds,
        jobTitle,
        stars,
        ratingsCount,
        shouldDisplaySurvey,
        isSurveyInit,
        hasSurveyErrored,
        gallery,
        graphic,
        branch,
        profile,
        licenseLink,
    } = props;

    // page has About Care widget
    const aboutCareWidget = modules.find(module => {
        return module.type === "aboutCare";
    });
    const hasAboutCare = aboutCareWidget?.display;

    // page has Care Testimonials widget
    const careTestimonialsWidget = modules.find(module => {
        return module.type === "careTestimonials";
    });
    const hasTestimonials =
        careTestimonialsWidget?.careTestimonials?.length > 0 &&
        careTestimonialsWidget?.display;

    // page has About BE widget
    const aboutBEWidget = modules.find(module => {
        return module.type === "aboutBE";
    });
    const hasAboutBE = aboutBEWidget?.display;

    // page has Profile widget
    const profileWidget = modules.find(module => {
        return module.type === "profile";
    });
    const hasStartLoanProcess = profileWidget?.display;

    const contact = loanOfficer ? loanOfficer : team ? team : null;
    const header = (
        <Header
            companyName={title}
            companyLogo={companyLogo}
            hasAboutCare={hasAboutCare}
            hasTestimonials={hasTestimonials}
            hasAboutBE={hasAboutBE}
            hasStartLoanProcess={hasStartLoanProcess}
            hero={hero}
        />
    );

    const moduleListProps = {
        firestoreId,
        modules,
        applyNow,
        applyNowScript,
        twitter: !!socialIcons ? socialIcons.twitter : "",
        contactInfo,
        isPreview,
        blogs,
        corporateBlogs,
        ownerImg: photo,
        title,
        review,
        isReviewInit,
        hasReviewErrored,
        blogWord,
        templateKey,
        owner,
        logIn,
        slug,
        ownerSlug,
        socialIcons,
        licenseIds,
        name: !!contactInfo ? contactInfo.name : "",
        jobTitle,
        email: !!contactInfo ? contactInfo.email : "",
        phone: !!contactInfo ? contactInfo.phone : "",
        mobilePhone: !!contactInfo ? contactInfo.mobilePhone : "",
        fax: !!contactInfo ? contactInfo.fax : "",
        team,
        stars,
        ratingsCount,
        shouldDisplaySurvey,
        testimonials,
        isSurveyInit,
        hasSurveyErrored,
        gallery: team ? { photo: profile?.logo } : gallery,
        graphic,
        branch,
        profile,
        pageTitle: title,
        pageUrl: "",
        leadEmailInfo,
        branches: [],
        realtor,
        pageType: "community-care",
        licenseLink,
    };

    if (!!team) {
        let teamLoanOfficers = team.loanOfficers?.map(lo => {
            const emptyLo = {
                slug: "",
                photo: "",
                name: "",
                email: "",
                jobTitle: "",
                licenseIds: "",
            };
            if (lo) {
                try {
                    const {
                        slug = "",
                        gallery,
                        profile: {
                            name = "",
                            email = "",
                            jobTitle = "",
                            licenseIds = [],
                        },
                    } = lo;

                    const photo = gallery && gallery.photo ? gallery.photo : "";

                    return {
                        slug,
                        photo,
                        name,
                        email,
                        jobTitle,
                        licenseIds,
                    };
                } catch (e) {
                    console.log(
                        "GivingToHeroesDetail team.loanOfficers map error for team" +
                            team.title +
                            ":  " +
                            e,
                    );
                    return emptyLo;
                }
            } else {
                console.log(
                    "GivingToHeroesDetail team.loanOfficers has null item for team " +
                        team.title,
                );
                return;
            }
        });
        moduleListProps.loanOfficers = teamLoanOfficers;
        moduleListProps.managers = team.managers;
        moduleListProps.staff = team.staff;
    } else if (!!branch) {
        let branchLoanOfficers = !!branch.loanOfficers
            ? branch.loanOfficers
                  .filter(lo => !!lo && !!lo.profile && !lo.hidden)
                  .map(lo => {
                      if (lo) {
                          try {
                              const {
                                  slug = "",
                                  gallery,
                                  profile: {
                                      name = "",
                                      email = "",
                                      jobTitle = "",
                                      licenseIds = [],
                                  },
                              } = lo;

                              const photo =
                                  gallery && gallery.photo ? gallery.photo : "";

                              return {
                                  slug,
                                  photo,
                                  name,
                                  email,
                                  jobTitle,
                                  licenseIds,
                              };
                          } catch (e) {
                              console.log(
                                  "GivingToHeroesDetail branch.loanOfficers map error for branch " +
                                      branch.title +
                                      ":  " +
                                      e,
                              );
                              return;
                          }
                      } else {
                          console.log(
                              "GivingToHeroesDetail branch.loanOfficers has null item for branch " +
                                  branch.title,
                          );
                          return;
                      }
                  })
            : [];

        let branchTeams = !!branch.teams
            ? branch.teams
                  .filter(t => !!t && !t.hidden)
                  .map(team => {
                      const emptyTeam = {
                          slug: "",
                          photo: "",
                          profile: {
                              name: "",
                              email: "",
                              jobTitle: "",
                              licenseIds: [],
                          },
                      };

                      try {
                          const { slug = "" } = team;

                          const licenseIds =
                              profile && profile.licenseIds
                                  ? profile.licenseIds
                                  : [];

                          return {
                              slug,
                              photo: "",
                              name: team.title,
                              email: team.profile.email,
                              jobTitle: "",
                              licenseIds: team.profile.licenseIds,
                          };
                      } catch (e) {
                          console.log("branch.teams map error:  " + e);
                          return emptyTeam;
                      }
                  })
            : [];

        let officers = branchLoanOfficers.concat(branchTeams);
        moduleListProps.loanOfficers = officers;
        moduleListProps.managers = branch.managers;
        moduleListProps.staff = branch.staff;
    }

    return (
        <Layout
            header={header}
            headBeginScript={headBeginScript}
            headEndScript={headEndScript}
            bodyBeginScript={bodyBeginScript}
            bodyEndScript={bodyEndScript}>
            <Helmet>
                <title>{`Bay Equity - Community Care - ${title}`}</title>
                <meta
                    name="description"
                    content="Bay Equity - Community Care"
                />
                <meta name="keywords" content="Bay Equity Community Care" />
                <meta name="viewport" content="width=device-width" />
                <script
                    src={`https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}`}></script>
                <html lang="en" />
            </Helmet>
            <div id="communityCarePage" className={styles.communityCarePage}>
                <Message
                    html={html}
                    messageFrom={messageFrom}
                    companyPhoto={companyPhoto}
                />
                <div className={styles.moduleText}>
                    <ModuleList {...moduleListProps} />
                </div>
            </div>
        </Layout>
    );
};

export default CommunityCareDetail;
