import React, { useState, useEffect } from 'react';

import HomePage from '../../HomePage';
import AboutUs from '../../../site-pages/about-us';
import OurTeam from '../../../site-pages/our-team';
import FhaLoan from '../../../site-pages/fha-loan';
import Servicing from '../../../site-pages/servicing';
import SplashDetail from '../../SplashDetail';
import JumboLoan from '../../../site-pages/jumbo-loan';
import HomeLoans from '../../../site-pages/home-loans';
import FixedRateLoan from '../../../site-pages/fixed-rate-mortgage';
import CareersPage from '../../../site-pages/bay-equity-careers';
import GetStartedPage from '../../../site-pages/get-started';
import SpecialtyLoans from '../../../site-pages/specialty-loans';
import AdjustableRateMortgage from '../../../site-pages/adjustable-rate-mortgage';
import VALoans from '../../../site-pages/va-loans';
import DownPaymentProgram from '../../../site-pages/down-payment-assistance-programs';

import Footer from '../../Layout/components/Footer';
import USDALoan from '../../../site-pages/usda-home-loan';

/**
 *
 * Flatten an entry for use in SplashDetail
 * Fetch loan officer, team, branch or non-Originator as needed from refs
 * Fetch lo blogs and corporate blogs
 */
const StaticTextPreview = ({ pageType, staticTextItems }) => {
   const [pageData, setPageData] = useState({});

   useEffect(() => {
      (() => {
         let data = {};
         data.isPreview = true;
         data.staticTextItems = staticTextItems;

         setPageData(data);
      })();
   }, [pageType]);

   let previewComponent = <></>;

   if (pageType === 'home')
      previewComponent = (
         <>
            <HomePage {...pageData}></HomePage>
            <Footer {...pageData}></Footer>
         </>
      );
   if (pageType === 'aboutUs') {
      const data = {
         corpBlogs: {}
      };
      previewComponent = <AboutUs {...pageData} data={data}></AboutUs>;
   }
   if (pageType === 'meetOurTeam') {
      previewComponent = <OurTeam {...pageData}></OurTeam>;
   }
   if (pageType === 'fhaLoan') {
      const data = {
         corpBlogs: {}
      };
      previewComponent = <FhaLoan {...pageData} data={data}></FhaLoan>;
   }
   if (pageType === 'jumboLoan') {
      const data = {
         corpBlogs: {}
      };
      previewComponent = <JumboLoan {...pageData} data={data}></JumboLoan>;
   }
   if (pageType == 'homeLoans') {
      const data = {
         corpBlogs: {}
      };
      previewComponent = <HomeLoans {...pageData} data={data}></HomeLoans>;
   }
   if (pageType == 'fixedRateMortgage') {
      const data = {
         corpBlogs: {}
      };
      previewComponent = <FixedRateLoan {...pageData} data={data}></FixedRateLoan>;
   }
   if (pageType === 'servicing') previewComponent = <Servicing {...pageData}></Servicing>;
   if (pageType === 'careers') previewComponent = <CareersPage {...pageData}></CareersPage>;
   if (pageType === 'get-started') {
      const data = {
         corpBlogs: {}
      };
      previewComponent = <GetStartedPage {...pageData} data={data} />;
   }
   if (pageType === 'specialty-loans') {
      const data = {
         corpBlogs: {}
      };
      previewComponent = <SpecialtyLoans {...pageData} data={data} />;
   }
   if (pageType === 'adjustable-rate-mortgage') {
      const data = {
         corpBlogs: {}
      };
      previewComponent = <AdjustableRateMortgage {...pageData} data={data} />;
   }
   if (pageType === 'vaLoans') {
      const data = {
         corpBlogs: {}
      };
      previewComponent = <VALoans {...pageData} data={data} />;
   }
   if (pageType === 'downPaymentPrograms') {
      const data = {
         corpBlogs: {}
      };
      previewComponent = <DownPaymentProgram {...pageData} data={data} />;
   }
   if (pageType === 'usdaHomeLoan') {
      const data = {
         corpBlogs: {}
      };
      previewComponent = <USDALoan {...pageData} data={data} />;
   }

   if (pageType === 'widget') {
      const entry = {
         title: 'Static Text Preview',
         vanityUrl: '',
         email: '',
         urlslug: '',
         hidden: false,
         disclaimer: false,
         dbaLogoBlack: '',
         headerImage: 'couple',
         headerImageCustom: '',
         headerImageAlignment: 'center',
         headerTitle: 'Static Text Preview',
         headerSubTitle: 'Scroll down to see widget static text',
         headerDarkText: true,
         displayApplyNow: false,
         loanOfficer: null,
         branch: null,
         team: null,
         nonOriginator: null,
         modules: [
            {
               type: 'teamMembers',
               display: false
            },
            {
               social_id: '',
               social_page: '',
               display: false,
               type: 'testimonials'
            },
            {
               backgroundImage: null,
               display: false,
               darkTitleText: true,
               calendar: '',
               type: 'calendar'
            },
            {
               backgroundImage: null,
               display: false,
               darkTitleText: true,
               bookings: '',
               type: 'bookings'
            },
            {
               showCalculatorRefinance: true,
               showCalculatorMortgage: true,
               type: 'calculator',
               display: true,
               showCalculatorRentVsBuy: true,
               showCalculatorHomeAffordability: true,
               showCalculatorRequiredIncome: true
            },
            {
               type: 'facebookpage',
               facebookPageUrl: '',
               display: false
            },
            {
               display: false,
               type: 'map'
            },
            {
               display: false,
               type: 'video',
               videos: [
                  {
                     videoCaption:
                        'Bay Equity is family owned and operated. This video is about our start and our technology-driven relationship with Microsoft.',
                     videoTitle: 'Built on family and forward-thinking',
                     videoUrl: 'https://youtu.be/AZXAJId6qMo'
                  }
               ]
            },
            {
               type: 'blog',
               display: false
            },
            {
               display: false,
               type: 'instagram'
            },
            {
               type: 'resources',
               resourceIntro:
                  'Here you’ll find a number of different downloadable PDFs, PowerPoints and other important documents to help make your loan process that much smoother!',
               display: false
            },
            {
               type: 'twitter',
               twitter: 'https://x.com/BayEquity',
               display: false
            },
            {
               event3: {
                  eventCta: '',
                  eventCustomImage: '',
                  eventDate: '',
                  eventDescription: '',
                  eventImage: '',
                  eventLocation: '',
                  eventName: '',
                  eventTime: '',
                  eventUrl: ''
               },
               event2: {
                  eventCta: '',
                  eventCustomImage: '',
                  eventDate: '',
                  eventDescription: '',
                  eventImage: '',
                  eventLocation: '',
                  eventName: '',
                  eventTime: '',
                  eventUrl: ''
               },
               display: false,
               type: 'events',
               event1: {
                  eventCta: '',
                  eventCustomImage: '',
                  eventDate: '',
                  eventDescription: '',
                  eventImage: '',
                  eventLocation: '',
                  eventName: '',
                  eventTime: '',
                  eventUrl: ''
               }
            },
            {
               display: false,
               type: 'awards'
            },
            {
               type: 'homebot',
               id: '',
               display: false
            },
            {
               type: 'profile',
               display: false,
               profileShowCompactView: false
            },
            {
               type: 'content',
               display: false,
               contentImage: '',
               contentTitle: '',
               contentText: ''
            },
            {
               type: 'contactUs',
               display: true,
               contactUsTitle: 'Static Page Preview',
               contactUsHtml: '',
               darkTitleText: true,
               backgroundImage: 'whiteWood',
               backgroundImageCustom: '',
               contactFormTitle: 'Static Page Preview',
               contactFormFields: [
                  {
                     type: 'firstName',
                     required: false
                  },
                  {
                     type: 'lastName',
                     required: false
                  },
                  {
                     type: 'phone',
                     required: false
                  },
                  {
                     type: 'email',
                     required: false
                  },
                  {
                     type: 'state',
                     required: true
                  }
               ],
               contactUsBammUrl: '',
               contactUsLeadEmailCcList: '',
               contactUsConfirmEmailFrom: '',
               contactUsConfirmEmailText: '',
               confirmEmailFrom: '',
               confirmEmailText: ''
            }
         ],
         headBeginScript: '',
         headEndScript: '',
         bodyBeginScript: '',
         bodyEndScript: ''
      };

      previewComponent = (
         <>
            <SplashDetail {...pageData} {...entry}></SplashDetail>
         </>
      );
   }

   return (
      <div id="preview" style={{ pointerEvents: 'auto' }}>
         {previewComponent}
      </div>
   );
};

export default StaticTextPreview;
