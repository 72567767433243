import React, { useState, useEffect } from 'react';
import Frame from 'react-frame-component';
import SplitPane from 'react-split-pane';
import { noop, isEmpty } from 'lodash';

import { geocode } from '../../../../../lib/geo';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Typography from '@material-ui/core/Typography';

// intro
import Title from '../Title';
import Owner from '../Owner';
import UrlSlug from '../UrlSlug';
import HidePage from '../HidePage';
import Disclaimer from '../Disclaimer';

// hero
import HeroImage from '../HeroImage';
import PhotoAlignment from '../PhotoAlignment';

import Uploader from '../Uploader';

// branch crew
import LoanOfficers from '../LoanOfficers';
import Teams from '../Teams';
import Staff from '../Staff';

// Contact Information
import AddressLine from '../AddressLine';
import City from '../City';
import State from '../State';
import ZipCode from '../Zip';
import Email from '../Email';
import Telephone from '../Telephone';

import Number from '../Number';

// widgets!
import Widgets from '../Widgets';

// change log
import ChangeLog from '../ChangeLog';

// scripts
import CustomScript from '../CustomScript';

// preview view
import BranchPreview from '../BranchPreview';

import { heroPhotoAlignment } from '../../../lib/defaults';

import styles from './styles.module.less';
import './styles.less';

/**
 * Collect style elements from the current page for injection into preview iframe
 */
const getStyles = () => {
   let head = '';
   const sheets = Array.from(document.querySelectorAll('link[rel=stylesheet]'));
   const styles = Array.from(document.querySelectorAll('head style'));

   sheets.forEach(link => {
      head += link.outerHTML;
   });

   styles.forEach(style => {
      head += style.outerHTML;
   });

   return head;
};

const Branch = ({
   loaded = false,
   entry,
   errors,
   missing,
   isAdmin,
   email,
   handleChange = noop,
   handleListChange = noop,
   handleFile = noop,
   handleBlur = noop,
   fetchCollection = noop
}) => {
   // container for preview frame styles
   const [previewStyles, setPreviewStyles] = useState(null);
   // enables / disables pointer events on editor and preview during resizing
   const [blockInput, setBlockInput] = useState(false);

   useEffect(() => {
      if (!previewStyles) {
         setPreviewStyles(getStyles());
      }
   }, [previewStyles]);

   const onDragStarted = () => {
      setBlockInput(true);
   };

   const onDragFinished = () => {
      setBlockInput(false);
   };

   const hasErrorsInSection = (errors, section) => {
      let keys = Object.keys(errors);
      return keys.some(key => key.startsWith(section) && !!errors[key]);
   };

   const hasErrorsInFields = (errors, fields = []) => fields.some(field => !!errors[field]);

   const handleAddressChange = e => {
      handleChange(e);
      const { address1, address2, address3, city, state, zip } = entry;
      // do not geocode incomplete address
      if (!address1 || !city || !state || !zip) return;
      let address = `${address1} ${address2} ${address3} ${city}, ${state} ${zip}`;
      geocode({ address }).then(result => {
         if (result) {
            const { lat, lon } = result;
            // simulate events
            let eLat = {
               target: {
                  name: 'lat',
                  value: parseFloat(lat),
                  type: 'text'
               }
            };
            let eLon = {
               target: {
                  name: 'lon',
                  value: parseFloat(lon),
                  type: 'text'
               }
            };
            // set value
            handleChange(eLat);
            handleChange(eLon);
            // trigger validation
            handleBlur(eLat);
            handleBlur(eLon);
         }
      });
   };

   let contactInfoErrorColor = hasErrorsInFields(errors, [
      'email',
      'phone',
      'mobilePhone',
      'fax',
      'address1',
      'address2',
      'address3',
      'city',
      'state',
      'zip',
      'lat',
      'lon'
   ])
      ? 'error'
      : 'inherit';
   let heroErrorColor = hasErrorsInSection(errors, 'hero') ? 'error' : 'inherit';

   let loClassName = !!blockInput ? `${styles.Branch} ${styles.blockEntry}` : styles.Branch;
   let frmClassName = !!blockInput ? `${styles.previewFrame} ${styles.blockEntry}` : styles.previewFrame;

   // don't bother if we don't have an entry to display
   if (!loaded) return null;
   if (isEmpty(entry)) return null;

   return (
      <SplitPane
         split="vertical"
         minSize={320}
         defaultSize="50%"
         style={{
            height: 'calc(100% - 64px)'
         }}
         onDragStarted={onDragStarted}
         onDragFinished={onDragFinished}>
         <div className={loClassName}>
            <Title isAdmin={isAdmin} onChange={handleChange} value={entry.title} errors={errors} />
            <Owner isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} defaultValue={email} />
            <UrlSlug isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />

            {/* DBA Logo is admin onluy */}
            {!!isAdmin && (
               <Uploader
                  name="dbaLogoBlack"
                  label="DBA Logo"
                  hint="Optional DBA Logo. For best results use a full-color (e.g. black and orange) landscape formatted PNG image with a transparent background. Image should be cropped tightly around the logo. Alternate versions will be created dynamically."
                  entry={entry}
                  config={{
                     imagesOnly: true,
                     inputAcceptTypes: '.png',
                     effects: 'crop'
                     // imageShrink: "300x67", // NOTE: This is disabled and left here with a note. This version of the editor fouls up transparent PNGs
                  }}
                  handleFile={handleFile}
               />
            )}

            <HidePage isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />

            <Paper elevation={1}>
               <Box padding={2} margin={2}>
                  <Disclaimer isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
               </Box>
            </Paper>

            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="h3" variant="h6" color={heroErrorColor}>
                     Page header
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Box padding={2} margin={2}>
                     <HeroImage isAdmin={isAdmin} value={entry.hero.heroBackground} onChange={handleChange} />

                     <Uploader
                        name="hero.customPhoto"
                        label="Custom Photo"
                        hint="Upload your custom header photo (images must be 2881x2017, in jpg format)"
                        entry={entry}
                        value={entry.hero.customPhoto}
                        config={{
                           imagesOnly: true,
                           inputAcceptTypes: '.jpg, .jpeg',
                           crop: '16:9',
                           imageShrink: '2881x2017'
                        }}
                        handleFile={handleFile}
                     />
                     <PhotoAlignment
                        name="hero.photoAlignment"
                        value={entry.hero.photoAlignment}
                        onChange={handleChange}
                        defaultValue={heroPhotoAlignment}
                        entry={entry}
                        errors={errors}
                     />
                  </Box>
               </AccordionDetails>
            </Accordion>

            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="h3" variant="h6" color={contactInfoErrorColor}>
                     Contact information
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Box padding={2} margin={2}>
                     <Email isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
                     <Telephone
                        name="phone"
                        label="Telephone"
                        isAdmin={isAdmin}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                        value={entry.phone}
                     />
                     <Telephone
                        name="mobilePhone"
                        label="Mobile Phone"
                        hint="Format 555-555-5555"
                        isAdmin={isAdmin}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                     <Telephone
                        name="fax"
                        label="Fax"
                        hint="Format 555-555-5555"
                        isAdmin={isAdmin}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                     <AddressLine
                        name="address1"
                        label="Address 1"
                        onChange={handleAddressChange}
                        entry={entry}
                        errors={errors}
                     />
                     <AddressLine
                        name="address2"
                        label="Address 2"
                        onChange={handleAddressChange}
                        entry={entry}
                        errors={errors}
                     />
                     <AddressLine
                        name="address3"
                        label="Address 3"
                        onChange={handleAddressChange}
                        entry={entry}
                        errors={errors}
                     />
                     <City onChange={handleAddressChange} entry={entry} errors={errors} />
                     <State onChange={handleAddressChange} onBlur={handleBlur} entry={entry} errors={errors} />
                     <ZipCode onChange={handleAddressChange} entry={entry} errors={errors} />
                     <Number
                        name="lat"
                        label="Latitude"
                        step={0.1}
                        onChange={handleChange}
                        entry={entry}
                        value={entry.lat}
                        errors={errors}
                     />
                     <Number
                        name="lon"
                        value={entry.lon}
                        label="longitude"
                        step={0.1}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                  </Box>
               </AccordionDetails>
            </Accordion>

            <Staff
               field="managers"
               label="Managers and Leads"
               values={entry.managers}
               handleListChange={handleListChange}
            />

            <LoanOfficers values={entry.loanOfficers} handleListChange={handleListChange} />

            <Teams values={entry.teams} handleListChange={handleListChange} />

            <Staff values={entry.staff} handleListChange={handleListChange} />

            <Box mt={2}>
               <Widgets
                  entry={entry}
                  errors={errors}
                  missing={missing}
                  handleListChange={handleListChange}
                  isAdmin={isAdmin}
                  pageType="branch"
               />
            </Box>

            <ChangeLog isAdmin={isAdmin} onChange={handleChange} value={entry.changelog} />

            <CustomScript
               name="headBeginScript"
               label="HEAD Beginning Script"
               isAdmin={isAdmin}
               onChange={handleChange}
               entry={entry}
               errors={errors}
            />
            <CustomScript
               name="headEndScript"
               label="HEAD Ending Script"
               isAdmin={isAdmin}
               onChange={handleChange}
               entry={entry}
               errors={errors}
            />
            <CustomScript
               name="bodyBeginScript"
               label="BODY Beginning Script"
               isAdmin={isAdmin}
               onChange={handleChange}
               entry={entry}
               errors={errors}
            />
            <CustomScript
               name="bodyEndScript"
               label="BODY Ending Script"
               isAdmin={isAdmin}
               onChange={handleChange}
               entry={entry}
               errors={errors}
            />
         </div>
         <Frame
            frameBorder="none"
            className={frmClassName}
            head={
               <>
                  <style>{'html, body {background-color: #F6F6F6; margin: 0; padding: 0;}'}</style>
                  <base target="_blank" />
               </>
            }
            // #6973: Had to add Uploadcare's AD script directly in order to get it to process images in iFrame.
            initialContent={`<!DOCTYPE html><html><head>${previewStyles}</head><body><div class="frame-root">
                    <script>
                        (function(src, cb) {
                            var s = document.createElement("script");
                            s.setAttribute("src", src);
                            s.onload = cb;
                            (document.head || document.body).appendChild(s);
                        })("https://ucarecdn.com/libs/blinkloader/3.x/blinkloader.min.js", function() {
                            window.Blinkloader.optimize({
                                pubkey: "${process.env.GATSBY_UPLOADCARE_PUBLIC_KEY}",
                                fadeIn: true,
                                lazyload: true,
                                smartCompression: true,
                                responsive: true,
                                retina: false,
                                webp: true,
                            });
                        });
                    </script>
                    <script src="https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}"></script></div></body></html>`}>
            <BranchPreview
               fetchCollection={fetchCollection}
               entry={entry}
               loanOfficers={entry.loanOfficers}
               teams={entry.teams}
            />
         </Frame>
      </SplitPane>
   );
};

export default Branch;
