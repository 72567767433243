import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../components/Layout';
import Header from '../../components/Header';
import IntroHeader from '../../components/IntroHeader';
import Divider from '../../components/Divider';
import styles from './styles.module.less';
import StaticText from '../../components/StaticText';
import { imgBrand, alt, getPageTheme, siteNameShort } from '../../lib/brand';

var serviceImg = imgBrand('servicing.png');

const Servicing = ({ staticTextItems }) => {
   const header = <Header active={['tools-and-resources', 'servicing']} theme={getPageTheme()} />;

   const introHeader = 'Servicing';
   const par = <StaticText id="servicing-body-text" items={staticTextItems}></StaticText>;

   return (
      <Layout hasHero={false} header={header}>
         <Helmet>
            <title>{alt('Servicing')}</title>
            <meta
               name="description"
               content={`Please find important ${siteNameShort} Servicing. If you don’t find the answer you’re looking for, give us a call. We’re here to help.`}
            />
         </Helmet>
         <IntroHeader header={introHeader} par={par} img={serviceImg} />
         <Divider />
         <div className={styles.body}>
            <StaticText id="servicing-body" items={staticTextItems}></StaticText>
         </div>
      </Layout>
   );
};

export default Servicing;
