export const collectionUrls = {
   loanOfficer: '/admin/collections/loan-officer',
   team: '/admin/collections/team',
   branch: '/admin/collections/branch',
   nonOriginator: '/admin/collections/non-originator',
   blog: '/admin/collections/blog',
   corporateBlog: '/admin/collections/corporateBlog',
   redirects: '/admin/redirects',
   g2HPage: '/admin/collections/giving-to-heroes',
   corporateCarePage: '/admin/collections/corporate-care',
   communityCarePage: '/admin/collections/community-care',
   splashPage: '/admin/collections/splash',
   staticText: '/admin/static-text',
   defaultText: '/admin/default-text',
   salesLeadership: '/admin/sales-leadership',
   staticPage: '/admin/static-page'
};

export const entryUrls = {
   loanOfficer: '/admin/collections/loan-officer/entries/',
   team: '/admin/collections/team/entries/',
   branch: '/admin/collections/branch/entries/',
   nonOriginator: '/admin/collections/non-originator/entries/',
   blog: '/admin/collections/blog/entries/',
   corporateBlog: '/admin/collections/corporateBlog/entries/',
   g2HPage: '/admin/collections/giving-to-heroes/entries/',
   corporateCarePage: '/admin/collections/corporate-care/entries/',
   communityCarePage: '/admin/collections/community-care/entries/',
   splashPage: '/admin/collections/splash/entries/'
};

export const getCreateUrl = collection => `/admin/collections/${collection}/new`;
