import React, { Component } from 'react';
import { navigate } from 'gatsby';

import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LinearProgress from '@material-ui/core/LinearProgress';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import FolderIcon from '@material-ui/icons/Folder';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';
import GroupIcon from '@material-ui/icons/Group';
import DescriptionIcon from '@material-ui/icons/Description';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextFieldsIcon from '@material-ui/icons/TextFields';

import Loader from '../components/Loader';
import LoanOfficerListItem from '../components/LoanOfficerListItem';
import TeamListItem from '../components/TeamListItem';
import BranchListItem from '../components/BranchListItem';
import NonOriginatorListItem from '../components/NonOriginatorListItem';
import BlogListItem from '../components/BlogListItem';
import CorporateBlogListItem from '../components/CorporateBlogListItem';
import GivingToHeroesListItem from '../components/GivingToHeroesListItem';
import CorporateCareListItem from '../components/CorporateCareListItem';
import CommunityCareListItem from '../components/CommunityCareListItem';
import SplashPageListItem from '../components/SplashPageListItem';
import { getDocumentByRef } from '../../../lib/helpers';
import { imgBrand, isBe, siteNameShort } from '../../../lib/brand';
import { remove, escapeRegExp, debounce } from 'lodash';

import styles from './styles.module.less';

var logo = imgBrand(isBe ? 'be_logo_white.png' : 'rf_logo_white.png');

import { collectionUrls, getCreateUrl } from '../lib/urls';
import { collectionMap } from '../lib/defaults';

const eject = () => {
   navigate('/admin/collections/loan-officer');
   return;
};

export default class Collection extends Component {
   state = {
      published: [],
      draft: [],
      ready: [],
      terminated: [],
      filter: '',
      filterText: '',
      meta: {},
      loaded: false,
      filterByType: [],
      filterByLo: true,
      filterByTeam: true,
      filterByBranch: true,
      filterByNo: true,
      filterByHidden: true,
      filterByNonCobrand: true
   };

   async componentDidMount() {
      const { collection } = this.props;
      // check for known / expected collection
      if (!this.isCollectionValid(collection)) eject();
      // ensure default loaded state on remount
      this.setState({ loaded: false });
      await this.fetch(collection);
   }

   async componentDidUpdate(prev) {
      if (prev.collection !== this.props.collection) {
         // unset filters and loaded state, then reload
         this.setState(
            {
               loaded: false,
               filter: '',
               filterText: '',
               filterByType: [],
               filterByLo: true,
               filterByTeam: true,
               filterByBranch: true,
               filterByNo: true,
               filterByHidden: true,
               filterByNonCobrand: true
            },
            async () => {
               // load collection once load state is set
               await this.fetch(this.props.collection);
            }
         );
      }
   }

   isCollectionValid(collection) {
      let list = Object.keys(collectionMap);
      return list.includes(collection);
   }

   where() {
      const { isAdmin, email } = this.props;
      if (isAdmin) return null;
      let field = 'owner',
         operator = '==',
         value = email;
      return {
         field,
         operator,
         value
      };
   }

   orderBy(collection) {
      if (collection.indexOf('blog') > -1) {
         return {
            field: 'date',
            direction: 'desc'
         };
      }
      return {
         field: 'title',
         direction: 'asc'
      };
   }

   async setSubTitles(collection) {
      await Promise.all(
         collection.map(async doc => {
            var ownerDoc = await getDocumentByRef(doc.loanOfficer ? doc.loanOfficer : doc.team ? doc.team : doc.branch);
            // if branch, use title, else use profile.name
            var ownerName = ownerDoc ? (doc.branch ? ownerDoc.title : ownerDoc.profile.name) : 'not set';
            doc.subTitle = `owner: ${ownerName}${!!doc.realtor?.name ? `, co-branded: ${doc.realtor.name}` : ''}`;
         })
      );
   }

   async fetch(collection) {
      const { getCollectionMeta, fetchCollection } = this.props;
      let meta = getCollectionMeta(collection);
      let published = await fetchCollection(collection, 'published', this.where(), this.orderBy(collection));
      let draft = await fetchCollection(collection, 'draft', this.where());

      if (collection === 'giving-to-heroes') {
         // show the base page first in the list
         let publishedBasePageIndex = published.map(p => p.title).indexOf('Giving to Heroes Base Page');

         if (publishedBasePageIndex !== -1) {
            let basePage = published[publishedBasePageIndex];
            published.splice(publishedBasePageIndex, 1);
            published.unshift(basePage);
         }

         let draftBasePageIndex = draft.map(p => p.title).indexOf('Giving to Heroes Base Page');

         if (draftBasePageIndex !== -1) {
            let basePage = draft[draftBasePageIndex];
            draft.splice(draftBasePageIndex, 1);
            draft.unshift(basePage);
         }
      }

      // remove drafts from published for clarity
      let draftIds = draft.map(doc => doc.id);
      published = published.filter(doc => !draftIds.includes(doc.id));

      // if one of the supported collections, get the subtitles for display in page list
      if (['splash', 'community-care', 'corporate-care', 'giving-to-heroes'].includes(collection)) {
         await this.setSubTitles(published);
         await this.setSubTitles(draft);
      }

      // isolate ready and terminated
      let ready = remove(draft, doc => !!doc.readyForReview);
      let terminated = remove(published, doc => !!doc.terminated);

      this.setState({
         meta,
         published,
         draft,
         ready,
         terminated,
         loaded: true
      });
   }

   handleSearchChange = e => {
      const filterText = e.target.value;
      this.setState({ filterText });
      if (!filterText) {
         // apply empty filter immediately
         this.setState({ filter: filterText });
         return;
      }
      this.applySearchFilterToCollections(filterText);
   };

   applySearchFilterToCollections = debounce(filter => {
      this.setState({
         filter
      });
   }, 250);

   handleFilterChange = e => {
      this.setState({
         ...this.state,
         [e.target.name]: e.target.checked
      });
      this.applyTypeFilterToCollections();
   };

   applyTypeFilterToCollections = debounce(() => {
      let filter = [];
      if (!this.state.filterByLo) filter.push('loan-officer');
      if (!this.state.filterByTeam) filter.push('team');
      if (!this.state.filterByBranch) filter.push('branch');
      if (!this.state.filterByNo) filter.push('non-originator');
      if (!this.state.filterByHidden) filter.push('hidden');
      if (!this.state.filterByNonCobrand) filter.push('non-cobrand');
      this.setState({ filterByType: filter });
   }, 250);

   render() {
      const { collection, logout, isAdmin } = this.props;
      const {
         published,
         draft,
         ready,
         terminated,
         meta,
         loaded,
         filter,
         filterText,
         filterByType,
         filterByLo,
         filterByTeam,
         filterByBranch,
         filterByNo,
         filterByHidden,
         filterByNonCobrand
      } = this.state;
      const showProfileFilters = ['splash'].includes(collection);
      const showHiddenFilter = !['non-originator', 'blog'].includes(collection);
      const showCobrandFilter = ['splash', 'corporate-care', 'community-care', 'giving-to-heroes'].includes(collection);
      const { label = '', labelSingular = '' } = meta;

      const hasDrafts = loaded && draft.length > 0;
      const hasPublished = loaded && published.length > 0;
      const hasReady = loaded && ready.length > 0;
      const hasTerminated = loaded && terminated.length > 0;

      const createUrl = getCreateUrl(collection);

      const canCreateNew = isAdmin || collection === 'blog';

      return (
         <div className={styles.Collection}>
            {!loaded && <LinearProgress color="secondary" />}
            <AppBar position="sticky">
               <Toolbar>
                  <img
                     src={logo}
                     style={{
                        objectFit: 'initial',
                        width: '150px',
                        marginRight: 'auto',
                        paddingTop: '5px',
                        paddingBottom: '5px'
                     }}
                     alt={`${siteNameShort}  Content Management`}
                  />
                  <IconButton edge="end" color="inherit" onClick={logout} aria-label="Log out">
                     <AccountBoxIcon />
                  </IconButton>
               </Toolbar>
            </AppBar>
            <Grid container spacing={0}>
               <Grid item xs={3}>
                  <Paper className={styles.collectionNav} square>
                     <CollectionNav isAdmin={isAdmin} />
                  </Paper>
               </Grid>
               <Grid item xs={9}>
                  <Loader loading={!loaded} />
                  <div hidden={!loaded}>
                     <AppBar position="static" color="default" elevation={0}>
                        <Toolbar>
                           <Typography component="h1" variant="h5">
                              {label}
                           </Typography>
                           <TextField
                              classes={{ root: styles.search }}
                              placeholder={`Search ${label}`}
                              margin="none"
                              size="small"
                              type="search"
                              variant="outlined"
                              color="primary"
                              onChange={this.handleSearchChange}
                              value={filterText}
                              InputProps={{
                                 startAdornment: (
                                    <InputAdornment position="start">
                                       <SearchIcon />
                                    </InputAdornment>
                                 )
                              }}
                           />
                           {(showProfileFilters || showHiddenFilter || showCobrandFilter) && (
                              <div
                                 style={{
                                    fontFamily: 'Roboto',
                                    fontWeight: '400',
                                    marginLeft: '20px',
                                    marginRight: '10px',
                                    height: '100%'
                                 }}>
                                 Show:
                              </div>
                           )}
                           <FormGroup
                              row
                              style={{
                                 paddingTop: '1px',
                                 height: '40px',
                                 paddingLeft: '10px'
                              }}>
                              {showProfileFilters && (
                                 <>
                                    <FormControlLabel
                                       control={
                                          <Checkbox
                                             color="primary"
                                             checked={filterByLo}
                                             onChange={this.handleFilterChange}
                                             icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                             checkedIcon={<CheckBoxIcon fontSize="small" />}
                                             name="filterByLo"
                                          />
                                       }
                                       label="Loan Officer"
                                    />
                                    <FormControlLabel
                                       control={
                                          <Checkbox
                                             color="primary"
                                             checked={filterByTeam}
                                             onChange={this.handleFilterChange}
                                             icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                             checkedIcon={<CheckBoxIcon fontSize="small" />}
                                             name="filterByTeam"
                                          />
                                       }
                                       label="Team"
                                    />
                                    <FormControlLabel
                                       control={
                                          <Checkbox
                                             color="primary"
                                             checked={filterByBranch}
                                             onChange={this.handleFilterChange}
                                             icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                             checkedIcon={<CheckBoxIcon fontSize="small" />}
                                             name="filterByBranch"
                                          />
                                       }
                                       label="Branch"
                                    />
                                    <FormControlLabel
                                       control={
                                          <Checkbox
                                             color="primary"
                                             checked={filterByNo}
                                             onChange={this.handleFilterChange}
                                             icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                             checkedIcon={<CheckBoxIcon fontSize="small" />}
                                             name="filterByNo"
                                          />
                                       }
                                       label="Non-Originator"
                                    />
                                 </>
                              )}
                              {showHiddenFilter && (
                                 <FormControlLabel
                                    control={
                                       <Checkbox
                                          color="primary"
                                          checked={filterByHidden}
                                          onChange={this.handleFilterChange}
                                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                                          name="filterByHidden"
                                       />
                                    }
                                    label="Hidden"
                                 />
                              )}
                              {showCobrandFilter && (
                                 <FormControlLabel
                                    control={
                                       <Checkbox
                                          color="primary"
                                          checked={filterByNonCobrand}
                                          onChange={this.handleFilterChange}
                                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                                          name="filterByNonCobrand"
                                       />
                                    }
                                    label="Non Co-Brand"
                                 />
                              )}
                           </FormGroup>
                           {!!canCreateNew && (
                              <Button
                                 classes={{ root: styles.button }}
                                 variant="contained"
                                 color="primary"
                                 onClick={() => navigate(createUrl)}>
                                 New {labelSingular}
                              </Button>
                           )}
                        </Toolbar>
                     </AppBar>
                     <List>
                        {hasReady && (
                           <>
                              <ListItem>
                                 <ListItemText>
                                    <Typography component="h2" variant="subtitle2">
                                       Ready for review
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                              <ProfileList
                                 collection={collection}
                                 profiles={ready}
                                 filter={filter}
                                 filterByType={filterByType}
                              />
                           </>
                        )}
                        {hasDrafts && (
                           <>
                              <ListItem>
                                 <ListItemText>
                                    <Typography component="h2" variant="subtitle2">
                                       Not Published
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                              <ProfileList
                                 collection={collection}
                                 profiles={draft}
                                 filter={filter}
                                 filterByType={filterByType}
                              />
                           </>
                        )}
                        {!!hasPublished && (
                           <>
                              <ListItem>
                                 <ListItemText>
                                    <Typography component="h2" variant="subtitle2">
                                       Published
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                              <ProfileList
                                 collection={collection}
                                 profiles={published}
                                 filter={filter}
                                 filterByType={filterByType}
                              />
                           </>
                        )}
                        {!!hasTerminated && (
                           <>
                              <ListItem>
                                 <ListItemText>
                                    <Typography component="h2" variant="subtitle2">
                                       Terminated
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                              <ProfileList
                                 collection={collection}
                                 profiles={terminated}
                                 filter={filter}
                                 filterByType={filterByType}
                              />
                           </>
                        )}
                     </List>
                  </div>
               </Grid>
            </Grid>
         </div>
      );
   }
}

Collection.defaultProps = {
   collection: '',
   isAdmin: false,
   isAuthenticated: false
};

const CollectionNav = ({ isAdmin = false }) => {
   const handleClick = to => e => {
      e.preventDefault();
      navigate(to);
   };

   return (
      <List component="nav">
         <ListItem divider>
            <Typography component="h1" variant="h6">
               Collections
            </Typography>
         </ListItem>
         <ListItem button onClick={handleClick(collectionUrls.loanOfficer)}>
            <ListItemIcon>
               <FolderIcon />
            </ListItemIcon>
            <ListItemText>Loan Officers</ListItemText>
         </ListItem>
         <ListItem button onClick={handleClick(collectionUrls.team)}>
            <ListItemIcon>
               <FolderIcon />
            </ListItemIcon>
            <ListItemText>Teams</ListItemText>
         </ListItem>
         <ListItem button onClick={handleClick(collectionUrls.branch)}>
            <ListItemIcon>
               <FolderIcon />
            </ListItemIcon>
            <ListItemText>Branches</ListItemText>
         </ListItem>
         <ListItem button onClick={handleClick(collectionUrls.nonOriginator)}>
            <ListItemIcon>
               <FolderIcon />
            </ListItemIcon>
            <ListItemText>Non-Originators</ListItemText>
         </ListItem>
         <ListItem button onClick={handleClick(collectionUrls.blog)}>
            <ListItemIcon>
               <FolderIcon />
            </ListItemIcon>
            <ListItemText>LO Blog Posts</ListItemText>
         </ListItem>
         {!!isAdmin && (
            <>
               <ListItem button onClick={handleClick(collectionUrls.corporateBlog)}>
                  <ListItemIcon>
                     <FolderIcon />
                  </ListItemIcon>
                  <ListItemText>Corporate Blog Posts</ListItemText>
               </ListItem>
               <ListItem button onClick={handleClick(collectionUrls.g2HPage)}>
                  <ListItemIcon>
                     <FolderIcon />
                  </ListItemIcon>
                  <ListItemText>Giving To Heroes</ListItemText>
               </ListItem>
               <ListItem button onClick={handleClick(collectionUrls.corporateCarePage)}>
                  <ListItemIcon>
                     <FolderIcon />
                  </ListItemIcon>
                  <ListItemText>Corporate Care</ListItemText>
               </ListItem>
               <ListItem button onClick={handleClick(collectionUrls.communityCarePage)}>
                  <ListItemIcon>
                     <FolderIcon />
                  </ListItemIcon>
                  <ListItemText>Community Care</ListItemText>
               </ListItem>
               <ListItem button onClick={handleClick(collectionUrls.splashPage)}>
                  <ListItemIcon>
                     <FolderIcon />
                  </ListItemIcon>
                  <ListItemText>Splash Pages</ListItemText>
               </ListItem>
               <ListItem button onClick={handleClick(collectionUrls.redirects)}>
                  <ListItemIcon>
                     <ListIcon />
                  </ListItemIcon>
                  <ListItemText>URL Redirects</ListItemText>
               </ListItem>
               <ListItem button onClick={handleClick(collectionUrls.defaultText)}>
                  <ListItemIcon>
                     <TextFieldsIcon />
                  </ListItemIcon>
                  <ListItemText>Default Text</ListItemText>
               </ListItem>
               <ListItem button onClick={handleClick(collectionUrls.staticText)}>
                  <ListItemIcon>
                     <TextFieldsIcon />
                  </ListItemIcon>
                  <ListItemText>Static Text</ListItemText>
               </ListItem>
               <ListItem button onClick={handleClick(collectionUrls.salesLeadership)}>
                  <ListItemIcon>
                     <GroupIcon />
                  </ListItemIcon>
                  <ListItemText>Sales Leadership</ListItemText>
               </ListItem>
               <ListItem button onClick={handleClick(collectionUrls.staticPage)}>
                  <ListItemIcon>
                     <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText>Site Pages</ListItemText>
               </ListItem>
            </>
         )}
      </List>
   );
};

const ProfileList = ({ profiles, collection, filter, filterByType = [] }) => {
   // if collection == blog or corporateBlog sort by date
   // else sort by title
   let reg = !!filter ? new RegExp(escapeRegExp(filter), 'ig') : new RegExp();

   return (
      <>
         {profiles.map(profile => {
            const { id, title, subTitle = '', realtor } = profile;

            if (!title.match(reg) && !subTitle.match(reg)) return null;
            if (
               filterByType.includes(profile.templateKey) ||
               (filterByType.includes('hidden') && profile.hidden) ||
               (filterByType.includes('non-cobrand') && !realtor?.name)
            )
               return null;

            switch (collection) {
               case 'loan-officer':
                  return <LoanOfficerListItem key={id} loanOfficer={profile} />;
               case 'team':
                  return <TeamListItem key={id} team={profile} />;
               case 'branch':
                  return <BranchListItem key={id} branch={profile} />;
               case 'non-originator':
                  return <NonOriginatorListItem key={id} nonOriginator={profile} />;
               case 'blog':
                  return <BlogListItem key={id} blog={profile} />;
               case 'corporateBlog':
                  return <CorporateBlogListItem key={id} blog={profile} />;
               case 'giving-to-heroes':
                  return <GivingToHeroesListItem key={id} g2HPage={profile} />;
               case 'corporate-care':
                  return <CorporateCareListItem key={id} corporateCarePage={profile} />;
               case 'community-care':
                  return <CommunityCareListItem key={id} communityCarePage={profile} />;
               case 'splash':
                  return <SplashPageListItem key={id} splashPage={profile} />;
               default:
                  return null;
            }
         })}
      </>
   );
};
