import React, { Component } from 'react';
import { Editor, getEventTransfer } from 'slate-react';
import Plain from 'slate-plain-serializer';
import { Value, Document, Block, Text } from 'slate';

import { isKeyHotkey } from 'is-hotkey';
import { get, isEmpty, debounce, noop, uniq } from 'lodash';
import { basename } from 'path';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import BoldIcon from '@material-ui/icons/FormatBold';
import ItalicIcon from '@material-ui/icons/FormatItalic';
// import CodeIcon from "@material-ui/icons/Code";
import LinkIcon from '@material-ui/icons/Link';
import Heading1Icon from '@material-ui/icons/LooksOne';
import Heading2Icon from '@material-ui/icons/LooksTwo';
import QuoteIcon from '@material-ui/icons/FormatQuote';
import BulletListIcon from '@material-ui/icons/FormatListBulleted';
import NumberedListIcon from '@material-ui/icons/FormatListNumbered';
import AddImageIcon from '@material-ui/icons/AddPhotoAlternate';
import AddFileIcon from '@material-ui/icons/AttachFile';

import { slateToMarkdown, markdownToSlate, htmlToSlate } from './serializers';

import styles from './styles.module.less';

import { showDialog } from '../../../lib/uploadcare';
import { getUuidFromUrl, resizeTo } from '../../../../../lib/ucImage';

// default node is a <p>
const DEFAULT_NODE = 'paragraph';

// hot keys for formatting
const isBoldHotkey = isKeyHotkey('mod+b');
const isItalicHotkey = isKeyHotkey('mod+i');
const isUnderlinedHotkey = isKeyHotkey('mod+u');
const isCodeHotkey = isKeyHotkey('mod+`');

const createEmptyRawDoc = () => {
   const emptyText = Text.create('');
   const emptyBlock = Block.create({
      object: 'block',
      type: 'paragraph',
      nodes: [emptyText]
   });
   return { nodes: [emptyBlock] };
};

const createSlateValue = rawValue => {
   const rawDoc = rawValue && markdownToSlate(rawValue);
   const rawDocHasNodes = !isEmpty(get(rawDoc, 'nodes'));
   const document = Document.fromJSON(rawDocHasNodes ? rawDoc : createEmptyRawDoc());
   return Value.create({ document });
};

/**
 * A change helper to standardize wrapping links.
 *
 * @param {Editor} editor
 * @param {String} href
 */
function wrapLink(editor, href) {
   let sanitized;
   try {
      let u = new URL(href);
      sanitized = u.href;
   } catch (err) {
      console.log('href', href);
      if (href.startsWith('/'))
         sanitized = href; // if the href starts with '/' assume it's a relative URL
      else sanitized = `http://${href}`; // otherwise, assume it's a lazily typed URL without a protocol
   }
   editor.wrapInline({
      type: 'link',
      data: { href: sanitized, url: sanitized }
   });

   editor.moveToEnd();
}

function wrapImageLink(editor, href) {
   editor.wrapBlock({
      type: 'link',
      data: { href, url: href }
   });
}

/**
 * A change helper to standardize unwrapping links.
 *
 * @param {Editor} editor
 */
function unwrapLink(editor) {
   editor.unwrapInline('link');
}

function unwrapImageLink(editor) {
   editor.unwrapBlock('link');
}

/**
 * A change function to standardize inserting images.
 *
 * @param {Editor} editor
 * @param {String} src
 * @param {Range} target
 */

function insertImage(editor, src, target) {
   if (target) {
      editor.select(target);
   }

   editor.insertBlock({
      type: 'image',
      data: { src, url: src }
   });
}

// Slate editor schema to support images as inline objects
const schema = {
   document: {
      last: { type: 'paragraph' },
      normalize: (editor, { code, node, child }) => {
         // using example code from Slate. Hence linting exceptions
         // eslint-disable-next-line
         switch (code) {
            case 'last_child_type_invalid': {
               const paragraph = Block.create('paragraph');
               return editor.insertNodeByKey(node.key, node.nodes.size, paragraph);
            }
         }
      }
   },
   blocks: {
      image: {
         isVoid: true
      }
   }
};

const VISUAL = 'visual';
const MARKDOWN = 'markdown';

/**
 * This editor configuration is shamelessly lifted from
 * https://github.com/ianstormtaylor/slate/blob/master/examples/rich-text/index.js
 * https://github.com/ianstormtaylor/slate/blob/master/examples/images/index.js
 * and
 * https://github.com/netlify/netlify-cms/blob/master/packages/netlify-cms-widget-markdown/src/MarkdownControl/VisualEditor.js
 *
 * with small alterations to use material UI
 */
export default class RichTextEditor extends Component {
   static defaultProps = {
      entry: {},
      defaultValue: '',
      placeholder: '',
      onChange: noop
   };

   constructor(props) {
      super(props);

      this.state = {
         mode: 'visual'
      };
   }

   handleSwitchMode = e => {
      let mode = !!e.target.checked ? MARKDOWN : VISUAL;
      this.setState({ mode });
   };

   render() {
      let { mode } = this.state;
      let { defaultValue, toolbarButtons } = this.props;
      let { body = defaultValue } = this.props.entry;
      const vis = (
         <VisualEditor
            mode={mode}
            placeholder={this.props.placeholder}
            onChange={this.props.onChange}
            body={body}
            onSwitchMode={this.handleSwitchMode}
            toolbarButtons={toolbarButtons}
         />
      );
      const raw = (
         <RawEditor
            mode={mode}
            placeholder={this.props.placeholder}
            onChange={this.props.onChange}
            body={body}
            onSwitchMode={this.handleSwitchMode}
            toolbarButtons={toolbarButtons}
         />
      );
      return mode === VISUAL ? vis : raw;
   }
}

class VisualEditor extends Component {
   static defaultProps = {
      mode: VISUAL,
      body: '',
      onChange: noop,
      onSwitchMode: noop,
      placeholder: ''
   };

   constructor(props) {
      super(props);
      const body = this.props.body;
      this.state = {
         value: createSlateValue(body),
         lastRawValue: this.props.body
      };
   }

   shouldComponentUpdate(nextProps, nextState) {
      const forcePropsValue = this.shouldForcePropsValue(
         this.props.value,
         this.state.lastRawValue,
         nextProps.value,
         nextState.lastRawValue
      );
      return !this.state.value.equals(nextState.value) || forcePropsValue;
   }

   componentDidUpdate(prevProps, prevState) {
      const forcePropsValue = this.shouldForcePropsValue(
         prevProps.value,
         prevState.lastRawValue,
         this.props.value,
         this.state.lastRawValue
      );

      if (forcePropsValue) {
         this.setState({
            value: createSlateValue(this.props.value),
            lastRawValue: this.props.value
         });
      }
   }

   // If the old props/state values and new state value are all the same, and
   // the new props value does not match the others, the new props value
   // originated from outside of this widget and should be used.
   shouldForcePropsValue(oldPropsValue, oldStateValue, newPropsValue, newStateValue) {
      return uniq([oldPropsValue, oldStateValue, newStateValue]).length === 1 && oldPropsValue !== newPropsValue;
   }

   /**
    * Check if the current selection has a mark with `type` in it.
    *
    * @param {String} type
    * @return {Boolean}
    */
   hasMark = type => {
      const { value } = this.state;
      return value.activeMarks.some(mark => mark.type === type);
   };

   /**
    * Check if the any of the currently selected blocks are of `type`.
    *
    * @param {String} type
    * @return {Boolean}
    */
   hasBlock = type => {
      const { value } = this.state;
      return value.blocks.some(node => node.type === type);
   };

   /**
    * Check whether the current selection has a link in it.
    *
    * @return {Boolean} hasLinks
    */
   hasLinks = () => {
      const { value } = this.state;
      return value.inlines.some(inline => inline.type === 'link');
   };

   hasImages = () => {
      const { value } = this.state;
      return value.blocks.some(block => block.type === 'image');
   };

   /**
    * Store a reference to the `editor`.
    *
    * @param {Editor} editor
    */
   ref = editor => {
      this.editor = editor;
   };

   /**
    * On change, save the new `value`.
    *
    * @param {Editor} editor
    */
   handleChange = ({ value }) => {
      if (!this.state.value.document.equals(value.document)) {
         this.handleDocumentChange(value);
      }
      this.setState({ value });
   };

   handlePaste = (e, editor, next) => {
      const transfer = getEventTransfer(e);
      if (transfer.type !== 'html') return next();
      const document = htmlToSlate(transfer.html);
      editor.insertFragment(document);
      next();
   };

   /**
    * debounced method to send content as markdown to parent editor form
    * via `this.props.onChange`
    */
   handleDocumentChange = debounce(value => {
      const { onChange } = this.props;
      const raw = value.document.toJSON();
      const markdown = slateToMarkdown(raw);
      this.setState({ lastRawValue: markdown }, () => onChange(markdown));
   }, 150);

   render() {
      const { mode, toolbarButtons = [] } = this.props;
      let buttons = toolbarButtons.length
         ? toolbarButtons.split('|')
         : ['bold', 'italic', 'link', 'head1', 'head2', 'quote', 'bullet', 'numbered', 'image', 'file', 'view'];
      return (
         <Paper className={styles.Editor}>
            <AppBar position="static" color="default">
               <Toolbar>
                  {buttons.includes('bold') && this.renderMarkButton('bold', <BoldIcon />)}
                  {buttons.includes('italic') && this.renderMarkButton('italic', <ItalicIcon />)}
                  {/* {this.renderMarkButton("code", <CodeIcon />)} */}
                  {buttons.includes('link') && this.renderLinkButton()}
                  {buttons.includes('head1') && this.renderBlockButton('heading-one', <Heading1Icon />)}
                  {buttons.includes('head2') && this.renderBlockButton('heading-two', <Heading2Icon />)}
                  {buttons.includes('quote') && this.renderBlockButton('quote', <QuoteIcon />)}
                  {buttons.includes('bullet') && this.renderBlockButton('bulleted-list', <BulletListIcon />)}
                  {buttons.includes('numbered') && this.renderBlockButton('numbered-list', <NumberedListIcon />)}
                  {buttons.includes('image') && this.renderImageButton()}
                  {buttons.includes('file') && this.renderFileButton()}
                  {buttons.includes('view') && (
                     <FormControlLabel
                        value="top"
                        classes={{ label: styles.modeSwitch }}
                        control={
                           <Switch
                              size="small"
                              color="primary"
                              checked={mode === MARKDOWN}
                              onChange={this.props.onSwitchMode}
                           />
                        }
                        label={mode}
                        labelPlacement="top"
                     />
                  )}
               </Toolbar>
            </AppBar>
            <Box padding={3}>
               <Editor
                  spellCheck
                  placeholder={this.props.placeholder}
                  ref={this.ref}
                  schema={schema}
                  value={this.state.value}
                  onChange={this.handleChange}
                  onKeyDown={this.onKeyDown}
                  onPaste={this.handlePaste}
                  renderBlock={this.renderBlock}
                  renderNode={this.renderBlock}
                  renderMark={this.renderMark}
                  renderInline={this.renderInline}
               />
            </Box>
         </Paper>
      );
   }

   /**
    * Render a mark-toggling toolbar button.
    *
    * @param {String} type
    * @param {Node} icon
    * @return {Element}
    */
   renderMarkButton = (type, icon) => {
      let color = this.hasMark(type) ? 'primary' : 'default';
      const { mode } = this.state;
      const disabled = mode === MARKDOWN;

      return (
         <IconButton color={color} disabled={disabled} onMouseDown={event => this.onClickMark(event, type)}>
            {icon}
         </IconButton>
      );
   };
   /**
    * Render a block-toggling toolbar button.
    *
    * @param {String} type
    * @param {String} icon
    * @return {Element}
    */

   renderBlockButton = (type, icon) => {
      let color = this.hasBlock(type) ? 'primary' : 'default';
      const { mode } = this.state;
      const disabled = mode === MARKDOWN;

      if (['numbered-list', 'bulleted-list'].includes(type)) {
         const {
            value: { document, blocks }
         } = this.state;

         if (blocks.size > 0) {
            const parent = document.getParent(blocks.first().key);
            color = this.hasBlock('list-item') && parent && parent.type === type ? 'primary' : 'default';
         }
      }

      return (
         <IconButton color={color} disabled={disabled} onMouseDown={event => this.onClickBlock(event, type)}>
            {icon}
         </IconButton>
      );
   };

   renderLinkButton = () => {
      let color = this.hasLinks() ? 'primary' : 'default';
      const { mode } = this.state;
      const disabled = mode === MARKDOWN;
      return (
         <IconButton disabled={disabled} color={color} onMouseDown={this.onClickLink}>
            <LinkIcon />
         </IconButton>
      );
   };

   renderImageButton = () => {
      let color = this.hasImages() ? 'primary' : 'default';
      const { mode } = this.state;
      const disabled = mode === MARKDOWN;
      return (
         <IconButton disabled={disabled} color={color} onMouseDown={this.onClickImage}>
            <AddImageIcon />
         </IconButton>
      );
   };

   renderFileButton = () => {
      const { mode } = this.state;
      const disabled = mode === MARKDOWN;
      return (
         <IconButton disabled={disabled} color="default" onMouseDown={this.onClickFile}>
            <AddFileIcon />
         </IconButton>
      );
   };

   /**
    * Render a Slate block.
    *
    * @param {Object} props
    * @return {Element}
    */

   renderBlock = (props, editor, next) => {
      const { attributes, children, node, isFocused } = props;
      switch (node.type) {
         case 'quote':
            return <blockquote {...attributes}>{children}</blockquote>;
         case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>;
         case 'heading-one':
            return <h1 {...attributes}>{children}</h1>;
         case 'heading-two':
            return <h2 {...attributes}>{children}</h2>;
         case 'list-item':
            return <li {...attributes}>{children}</li>;
         case 'numbered-list':
            return <ol {...attributes}>{children}</ol>;
         case 'link':
            const { data } = node;
            const href = data.get('url');
            return (
               <a target="_blank" rel="noopener noreferrer" href={href} {...attributes}>
                  {children}
               </a>
            );
         case 'image':
            const src = node.data.get('url');
            return (
               <img
                  {...attributes}
                  src={src}
                  alt=""
                  style={{
                     display: 'block',
                     maxWidth: '100%',
                     maxHeight: '20em',
                     boxShadow: `${isFocused ? '0 0 0 2px blue' : 'none'}`
                  }}
               />
            );
         default:
            return next();
      }
   };

   /**
    * Render a Slate mark.
    *
    * @param {Object} props
    * @return {Element}
    */
   renderMark = (props, editor, next) => {
      const { children, mark, attributes } = props;

      switch (mark.type) {
         case 'bold':
            return <strong {...attributes}>{children}</strong>;
         case 'code':
            return <code {...attributes}>{children}</code>;
         case 'italic':
            return <em {...attributes}>{children}</em>;
         case 'underlined':
            return <u {...attributes}>{children}</u>;
         default:
            return next();
      }
   };

   /**
    * Render a Slate inline.
    *
    * @param {Object} props
    * @return {Element}
    */
   renderInline = (props, editor, next) => {
      const { attributes, children, node, isFocused } = props;

      switch (node.type) {
         case 'link':
            const { data } = node;
            const href = data.get('url');
            return (
               <a target="_blank" rel="noopener noreferrer" href={href} {...attributes}>
                  {children}
               </a>
            );
         case 'image':
            const src = node.data.get('url');
            return (
               <img
                  {...attributes}
                  src={src}
                  alt=""
                  style={{
                     display: 'block',
                     maxWidth: '100%',
                     maxHeight: '20em',
                     boxShadow: `${isFocused ? '0 0 0 2px blue' : 'none'}`
                  }}
               />
            );
         default:
            return next();
      }
   };

   /**
    * On key down, if it's a formatting command toggle a mark.
    *
    * @param {Event} event
    * @param {Editor} editor
    * @return {Change}
    */
   onKeyDown = (event, editor, next) => {
      let mark;

      if (isBoldHotkey(event)) {
         mark = 'bold';
      } else if (isItalicHotkey(event)) {
         mark = 'italic';
      } else if (isUnderlinedHotkey(event)) {
         mark = 'underlined';
      } else if (isCodeHotkey(event)) {
         mark = 'code';
      } else {
         return next();
      }

      event.preventDefault();
      editor.toggleMark(mark);
   };

   /**
    * When a mark button is clicked, toggle the current mark.
    *
    * @param {Event} event
    * @param {String} type
    */
   onClickMark = (event, type) => {
      event.preventDefault();
      this.editor.toggleMark(type);
   };

   /**
    * When a block button is clicked, toggle the block type.
    *
    * @param {Event} e
    * @param {String} type
    */
   onClickBlock = (e, type) => {
      e.preventDefault();

      const { editor } = this;
      const { value } = editor;
      const { document } = value;

      // Handle everything but list buttons.
      if (type !== 'bulleted-list' && type !== 'numbered-list') {
         const isActive = this.hasBlock(type);
         const isList = this.hasBlock('list-item');

         if (isList) {
            editor
               .setBlocks(isActive ? DEFAULT_NODE : type)
               .unwrapBlock('bulleted-list')
               .unwrapBlock('numbered-list');
         } else {
            editor.setBlocks(isActive ? DEFAULT_NODE : type);
         }
      } else {
         // Handle the extra wrapping required for list buttons.
         const isList = this.hasBlock('list-item');
         const isType = value.blocks.some(block => {
            return !!document.getClosest(block.key, parent => parent.type === type);
         });

         if (isList && isType) {
            editor.setBlocks(DEFAULT_NODE).unwrapBlock('bulleted-list').unwrapBlock('numbered-list');
         } else if (isList) {
            editor.unwrapBlock(type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list').wrapBlock(type);
         } else {
            editor.setBlocks('list-item').wrapBlock(type);
         }
      }
   };

   /**
    * When clicking a link, if the selection has a link in it, remove the link.
    * Otherwise, add a new link with an href and text.
    *
    * @param {Event} e
    */
   onClickLink = e => {
      e.preventDefault();

      const { editor } = this;
      const { value } = editor;
      const hasLinks = this.hasLinks();
      const hasImages = this.hasImages();

      if (hasLinks) {
         editor.command(unwrapLink);
      } else if (hasImages) {
         editor.command(unwrapImageLink);
         const href = window.prompt('Enter the URL of the link (e.g. https://www.example.com):');
         editor.command(wrapImageLink, href);
      } else if (value.selection.isExpanded) {
         const href = window.prompt('Enter the URL of the link  (e.g. https://www.example.com):');

         if (href == null) {
            return;
         }

         editor.command(wrapLink, href);
      } else {
         const href = window.prompt('Enter the URL of the link:');

         if (href == null) {
            return;
         }

         const text = window.prompt('Enter the text for the link:');

         if (text == null) {
            return;
         }

         editor.insertText(text).moveFocusBackward(text.length).command(wrapLink, href);
      }
   };

   onClickImage = e => {
      const config = {
         allow_multiple: false, // eslint-disable-line camelcase
         imagesOnly: true
      };
      showDialog(null, config, cdnUrl => {
         let resized = resizeTo(getUuidFromUrl(cdnUrl), 1024, 1024);
         this.editor.command(insertImage, resized);
      });
   };

   onClickFile = e => {
      const config = {
         allow_multiple: false // eslint-disable-line camelcase
      };
      showDialog(null, config, cdnUrl => {
         let pathname = new URL(cdnUrl).pathname;
         let filename = basename(pathname);
         this.editor.insertText(filename).moveFocusBackward(filename.length).command(wrapLink, cdnUrl);
      });
   };
}

class RawEditor extends Component {
   static defaultProps = {
      mode: MARKDOWN,
      body: '',
      onChange: noop,
      onSwitchMode: noop,
      placeholder: ''
   };
   constructor(props) {
      super(props);
      this.state = {
         value: Plain.deserialize(this.props.body || ''),
         lastRawValue: this.props.body || ''
      };
   }

   shouldComponentUpdate(nextProps, nextState) {
      return !this.state.value.equals(nextState.value);
   }

   handleChange = ({ value }) => {
      if (!this.state.value.document.equals(value.document)) {
         this.handleDocumentChange(value);
      }
      this.setState({ value });
   };

   handleDocumentChange = debounce(value => {
      const { onChange } = this.props;
      const markdown = Plain.serialize(value);
      this.setState({ lastRawValue: markdown }, () => onChange(markdown));
   }, 150);

   handlePaste = (e, editor, next) => {
      const transfer = getEventTransfer(e);
      if (transfer.type !== 'text') return next();
      const fragment = Plain.deserialize(transfer.text).document;
      editor.insertFragment(fragment);
   };

   render() {
      const { mode } = this.props;
      return (
         <Paper className={styles.Editor}>
            <AppBar position="static" color="default">
               <Toolbar>
                  <IconButton disabled>
                     <BoldIcon />
                  </IconButton>
                  <IconButton disabled>
                     <ItalicIcon />
                  </IconButton>
                  <IconButton disabled>
                     <LinkIcon />
                  </IconButton>
                  <IconButton disabled>
                     <Heading1Icon />
                  </IconButton>
                  <IconButton disabled>
                     <Heading2Icon />
                  </IconButton>
                  <IconButton disabled>
                     <QuoteIcon />
                  </IconButton>
                  <IconButton disabled>
                     <BulletListIcon />
                  </IconButton>
                  <IconButton disabled>
                     <NumberedListIcon />
                  </IconButton>
                  <IconButton disabled>
                     <AddImageIcon />
                  </IconButton>
                  <IconButton disabled>
                     <AddFileIcon />
                  </IconButton>
                  <FormControlLabel
                     value="top"
                     classes={{ label: styles.modeSwitch }}
                     control={
                        <Switch
                           size="small"
                           color="primary"
                           checked={mode === MARKDOWN}
                           onChange={this.props.onSwitchMode}
                        />
                     }
                     label={mode}
                     labelPlacement="top"
                  />
               </Toolbar>
            </AppBar>
            <Box padding={3}>
               <Editor
                  className={styles.plainText}
                  value={this.state.value}
                  onChange={this.handleChange}
                  onPaste={this.handlePaste}
               />
            </Box>
         </Paper>
      );
   }
}
