import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const name = 'hidden';
const hint = 'Hide this profile from web site visitors';
const label = 'Hide Page';

const HidePage = ({
   isAdmin,
   value: inValue,
   entry = null,
   errors,
   disabled = false,
   hint: inHint = hint,
   ...rest
}) => {
   const errorMsg = errors[name];
   const value = entry ? entry[name] : inValue;
   let helperText = errorMsg || inHint;
   const id = `entry-${name}`;
   return (
      <FormControl hidden={!isAdmin} fullWidth={true} margin="normal">
         <FormControlLabel
            label={label}
            labelPlacement="end"
            control={
               <Switch
                  color="primary"
                  id={id}
                  name={name}
                  checked={value}
                  disabled={disabled}
                  inputProps={{
                     'data-label': label
                  }}
                  {...rest}
               />
            }
         />
         <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
   );
};

export default HidePage;
