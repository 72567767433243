import React from 'react';
import GetStartedBlock from '../GetStartedBlock';
import Accent from '../Accent';
import { altSuffix, img } from '../../lib/brand';
import styles from './styles.module.less';

var getStarted1Img = img('getstarted2.jpg');
var getStarted2Img = img('GetStarted-2.jpg');
var getStarted3Img = img('GetStarted-3.jpg');
var getStarted4Img = img('getstarted1.jpg');
var getStarted5Img = img('GetStarted-5.jpg');
var getStarted6Img = img('GetStarted-6.jpg');
var getStarted7Img = img('getstarted3.jpg');
var getStarted8Img = img('GetStarted-8.jpg');

interface GetStartedContentProps {
   staticTextItems: [];
}

const GetStartedContent = (props: GetStartedContentProps) => {
   const { staticTextItems } = props;

   return (
      <section>
         <section className={styles.GetStartedIntro}>
            <div className={styles.Container}>
               <h1>8 steps to buying your first home</h1>
               <Accent />
               <p>
                  If you’re a first-time homebuyer who’s decided it’s time to <em>stop</em> dreaming and <em>start</em>{' '}
                  doing, then we’re here to support you through the home-buying process. After all, this is an exciting
                  time.
               </p>
            </div>
         </section>

         <GetStartedBlock
            subtitle="Step One"
            title="get-started-step1-title"
            description="get-started-step1-desc"
            icon={getStarted1Img}
            flexD="row-reverse"
            alt={`Figure Out What You Can Afford - Get Started Buying a Home${altSuffix}`}
            staticTextItems={staticTextItems}
         />
         <GetStartedBlock
            subtitle="Step Two"
            title="get-started-step2-title"
            description="get-started-step2-desc"
            icon={getStarted2Img}
            flexD="row"
            alt={`Get Preapproved for Your Mortgage - Get Started Buying a Home${altSuffix}`}
            staticTextItems={staticTextItems}
         />
         <GetStartedBlock
            subtitle="Step Three"
            title="get-started-step3-title"
            description="get-started-step3-desc"
            icon={getStarted3Img}
            flexD="row-reverse"
            alt={`Research Real Estate and Realtors - Get Started Buying a Home${altSuffix}`}
            staticTextItems={staticTextItems}
         />
         <GetStartedBlock
            subtitle="Step Four"
            title="get-started-step4-title"
            description="get-started-step4-desc"
            icon={getStarted4Img}
            flexD="row"
            alt={`Make an Offer on the Right House - Get Started Buying a Home${altSuffix}`}
            staticTextItems={staticTextItems}
         />

         <GetStartedBlock
            subtitle="Step Five"
            title="get-started-step5-title"
            description="get-started-step5-desc"
            icon={getStarted5Img}
            flexD="row-reverse"
            alt={`Select Your Home Loan Program - Get Started Buying a Home${altSuffix}`}
            staticTextItems={staticTextItems}
         />
         <GetStartedBlock
            subtitle="Step Six"
            title="get-started-step6-title"
            description="get-started-step6-desc"
            icon={getStarted6Img}
            flexD="row"
            alt={`Have the Home Inspected - Get Started Buying a Home${altSuffix}`}
            staticTextItems={staticTextItems}
         />

         <GetStartedBlock
            subtitle="Step Seven"
            title="get-started-step7-title"
            description="get-started-step7-desc"
            icon={getStarted7Img}
            flexD="row-reverse"
            alt={`Have the Home Appraised - Get Started Buying a Home${altSuffix}`}
            staticTextItems={staticTextItems}
         />
         <GetStartedBlock
            subtitle="Step Eight"
            title="get-started-step8-title"
            description="get-started-step8-desc"
            icon={getStarted8Img}
            flexD="row"
            alt={`Sign the Papers to Close the Sale - Get Started Buying a Home${altSuffix}`}
            staticTextItems={staticTextItems}
         />
      </section>
   );
};

export default GetStartedContent;
