import React, { useState, useEffect } from 'react';
import Frame from 'react-frame-component';
import SplitPane from 'react-split-pane';
import { noop, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Typography from '@material-ui/core/Typography';

// hero
import HeroImage from '../HeroImage';
import PhotoAlignment from '../PhotoAlignment';
import Editor from '../Editor';
import Boolean from '../Boolean';

// intro
import Title from '../Title';
import Email from '../Email';
import UrlSlug from '../UrlSlug';
import HidePage from '../HidePage';
import Disclaimer from '../Disclaimer';

// Profile
import Uploader from '../Uploader';
import Telephone from '../Telephone';
import AddressLine from '../AddressLine';
import City from '../City';
import State from '../State';
import ZipCode from '../Zip';
import Text from '../Text';
import Number from '../Number';
import LoanOfficerSelect from '../LoanOfficerSelect';
import TeamSelect from '../TeamSelect';
import BranchSelect from '../BranchSelect';

import Widgets from '../Widgets';

// change log
import ChangeLog from '../ChangeLog';

// scripts
import CustomScript from '../CustomScript';

// preview view
import GivingToHeroesPreview from '../GivingToHeroesPreview';

import styles from './styles.module.less';

/**
 * Collect style elements from the current page for injection into preview iframe
 */
const getStyles = () => {
   let head = '';
   const sheets = Array.from(document.querySelectorAll('link[rel=stylesheet]'));
   const styles = Array.from(document.querySelectorAll('head style'));

   sheets.forEach(link => {
      head += link.outerHTML;
   });

   styles.forEach(style => {
      head += style.outerHTML;
   });

   return head;
};

const stylesExt = theme => ({
   details: {
      flexDirection: 'column'
   },
   button: {
      margin: theme.spacing(1)
   }
});

const GivingToHeroes = ({
   loaded = false,
   // entry,
   errors,
   missing,
   isAdmin,
   isNew,
   isBasePage,
   classes,
   handleChange = noop,
   handleListChange = noop,
   handleFile = noop,
   handleRef = noop,
   handleBlur = noop,
   fetchCollection = noop,
   // entry props below
   id,
   title,
   name,
   email,
   urlslug,
   hidden,
   disclaimer,
   cobrandUrl,
   branch,
   loanOfficer,
   team,
   modules,
   realtor,
   bodyBeginScript,
   bodyEndScript,
   headBeginScript,
   headEndScript,
   changelog,
   hero
}) => {
   const entry = {
      title,
      name,
      email,
      urlslug,
      hidden,
      disclaimer,
      cobrandUrl,
      branch,
      loanOfficer,
      team,
      modules,
      realtor,
      bodyBeginScript,
      bodyEndScript,
      headBeginScript,
      headEndScript,
      changelog,
      hero
   };
   // container for preview frame styles
   const [previewStyles, setPreviewStyles] = useState(null);
   // enables / disables pointer events on editor and preview during resizing
   const [blockInput, setBlockInput] = useState(false);

   useEffect(() => {
      if (!previewStyles) {
         setPreviewStyles(getStyles());
      }
   }, [previewStyles]);

   const handleEditorChange = (field, value) => {
      handleChange({ target: { type: 'text', name: field, value: value } });
   };

   const onDragStarted = () => {
      setBlockInput(true);
   };

   const onDragFinished = () => {
      setBlockInput(false);
   };

   const hasErrorsInFields = (errors, fields = []) => fields.some(field => !!errors[field]);

   let heroErrorColor = hasErrorsInFields(errors, [
      'hero.heroBackground',
      'hero.customPhoto',
      'hero.photoAlignment',
      'hero.title',
      'hero.html'
   ])
      ? 'error'
      : 'inherit';

   let contactInfoErrorColor = hasErrorsInFields(errors, [
      'contact.address',
      'contact.city',
      'contact.state',
      'contact.zip',
      'contact.url',
      'contact.applyNow'
   ])
      ? 'error'
      : 'inherit';
   let realtorInfoErrorColor = hasErrorsInFields(errors, [
      'realtor.name',
      'realtor.title',
      'realtor.title2',
      'realtor.licenseNumber',
      'realtor.phone',
      'realtor.fax',
      'realtor.email',
      'realtor.url',
      'realtor.address',
      'realtor.city',
      'realtor.state',
      'realtor.zip',
      'realtor.photoUrl',
      'realtor.logoUrl'
   ])
      ? 'error'
      : 'inherit';

   let loClassName = !!blockInput ? `${styles.LoanOfficer} ${styles.blockEntry}` : styles.LoanOfficer;
   let frmClassName = !!blockInput ? `${styles.previewFrame} ${styles.blockEntry}` : styles.previewFrame;

   // don't bother if we don't have an entry to display
   if (!loaded) return null;
   if (isEmpty(entry)) return null;

   return (
      <SplitPane
         split="vertical"
         minSize={320}
         defaultSize="40%"
         style={{
            height: 'calc(100% - 64px)'
         }}
         onDragStarted={onDragStarted}
         onDragFinished={onDragFinished}>
         <div className={loClassName}>
            <Title
               hint="Should be Branch, Team or Loan Officer's name (or combination) used as URL slug (e.g. “John Smith” becomes “john-smith” slug)"
               isAdmin={isAdmin}
               disabled={isBasePage}
               onChange={handleChange}
               value={entry.title}
               errors={errors}
            />
            <Text
               required
               name="name"
               label="Name"
               hint="Name to display in list of nearest Branches and lead email (e.g. “Westford, MA (valente)”)"
               disabled={!isAdmin || isBasePage}
               hidden={!isAdmin}
               onChange={handleChange}
               entry={entry}
               errors={errors}
            />
            <Email
               name="email"
               label="Email"
               hint="Lead email address"
               isAdmin={isAdmin}
               onChange={handleChange}
               entry={entry}
               errors={errors}
            />
            <UrlSlug isAdmin={isAdmin} disabled={isBasePage} onChange={handleChange} entry={entry} errors={errors} />
            <HidePage
               isAdmin={isAdmin}
               onChange={handleChange}
               entry={entry}
               errors={errors}
               disabled={isBasePage}
               hint="Hide this page from web site visitors"
            />
            <Paper elevation={1}>
               <Box padding={2} margin={2}>
                  <Disclaimer isAdmin={isAdmin} onChange={handleChange} entry={entry} errors={errors} />
               </Box>
            </Paper>
            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="h3" variant="h6" color={heroErrorColor}>
                     Page Header
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Box padding={2} margin={2}>
                     <HeroImage
                        isAdmin={isAdmin}
                        collections={['Generic', 'Regional', 'Site Specific', 'G2H']}
                        value={entry.hero ? entry.hero.heroBackground : ''}
                        onChange={handleChange}
                     />

                     <Uploader
                        name="hero.customPhoto"
                        label="Custom Photo"
                        hint="Upload your custom header photo (images must be 2881x2017, in jpg format)"
                        entry={entry}
                        config={{
                           imagesOnly: true,
                           inputAcceptTypes: '.jpg, .jpeg',
                           crop: '16:9',
                           imageShrink: '2881x2017'
                        }}
                        handleFile={handleFile}
                     />
                     <PhotoAlignment
                        name="hero.photoAlignment"
                        value={entry.hero ? entry.hero.photoAlignment : ''}
                        onChange={handleChange}
                        defaultValue="center"
                        entry={entry}
                        errors={errors}
                     />
                     <Text
                        name="hero.title"
                        label="Title"
                        disabled={!isAdmin}
                        hidden={!isAdmin}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                     <div className={styles.contentEditor}>
                        <Editor
                           name="hero.html"
                           entry={{
                              body: entry.hero ? entry.hero.html : ''
                           }}
                           onChange={body => {
                              handleEditorChange('hero.html', body);
                           }}
                           toolbarButtons="bold|italic|link|bullet|numbered"
                        />
                     </div>
                     <Boolean
                        name="hero.darkText"
                        value={entry.hero.darkText}
                        label="Use Dark Text?"
                        hint="Display the Page Header text in black for better contrast"
                        onChange={handleChange}
                     />
                  </Box>
               </AccordionDetails>
            </Accordion>
            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="h3" variant="h6" color={contactInfoErrorColor}>
                     Loan Officer / Team / Branch
                  </Typography>
               </AccordionSummary>
               <AccordionDetails className={classes.details}>
                  <LoanOfficerSelect
                     isAdmin={isAdmin}
                     onRefSelect={handleRef}
                     onChange={handleChange}
                     entry={entry}
                     errors={errors}
                     depRefs={['team', 'branch']}
                  />
                  <br />
                  or
                  <br />
                  <TeamSelect
                     isAdmin={isAdmin}
                     onRefSelect={handleRef}
                     onChange={handleChange}
                     entry={entry}
                     errors={errors}
                     depRefs={['loanOfficer', 'branch']}
                  />
                  <br />
                  or
                  <br />
                  <BranchSelect
                     isAdmin={isAdmin}
                     onRefSelect={handleRef}
                     value={entry.branch}
                     onChange={handleChange}
                     errors={errors}
                     name="branch"
                     depRefs={[entry.loanOfficer, entry.team]}
                     defaultProps={{
                        name: 'branch',
                        docRef: 'entry.branch'
                     }}
                     isClearable
                  />
               </AccordionDetails>
            </Accordion>
            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="h3" variant="h6" color={realtorInfoErrorColor}>
                     Realtor
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Box padding={1} margin={1}>
                     <Text
                        name="realtor.name"
                        label="Name"
                        disabled={!isAdmin}
                        required={true}
                        hidden={!isAdmin}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                     <Text
                        name="realtor.title2"
                        label="Title"
                        hint="Realtor title (defaults to “Realtor”)"
                        disabled={!isAdmin}
                        hidden={!isAdmin}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                     <Text
                        name="realtor.title"
                        label="Company"
                        disabled={!isAdmin}
                        hidden={!isAdmin}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                     <Text
                        name="realtor.licenseNumber"
                        label="License #"
                        hint="Realtor license number"
                        disabled={!isAdmin}
                        hidden={!isAdmin}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                     <Telephone
                        name="realtor.phone"
                        label="Telephone"
                        hint="Format 555-555-5555"
                        isAdmin={isAdmin}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                     <Telephone
                        name="realtor.fax"
                        label="Fax"
                        hint="Format 555-555-5555"
                        isAdmin={isAdmin}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                     <Text
                        name="realtor.email"
                        label="Email"
                        disabled={!isAdmin}
                        hidden={!isAdmin}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                     <Text
                        name="realtor.url"
                        label="Site URL"
                        disabled={!isAdmin}
                        hidden={!isAdmin}
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                     <AddressLine
                        name="realtor.address"
                        label="Address"
                        onChange={handleChange}
                        entry={entry}
                        errors={errors}
                     />
                     <City name="realtor.city" onChange={handleChange} entry={entry} errors={errors} />
                     <State name="realtor.state" onChange={handleChange} entry={entry} errors={errors} />
                     <ZipCode name="realtor.zip" onChange={handleChange} entry={entry} errors={errors} />
                     <Uploader
                        name="realtor.photoUrl"
                        label="Photo"
                        hint="Upload realtor photo"
                        entry={entry}
                        config={{
                           imagesOnly: true
                        }}
                        defaultValue=""
                        handleFile={handleFile}
                        onChange={handleChange}
                     />
                     <Uploader
                        name="realtor.logoUrl"
                        label="Logo"
                        hint="Upload realtor logo"
                        entry={entry}
                        config={{
                           imagesOnly: true
                        }}
                        defaultValue=""
                        handleFile={handleFile}
                        onChange={handleChange}
                        logo
                     />
                  </Box>
               </AccordionDetails>
            </Accordion>

            <Box mt={2}>
               <Widgets
                  entry={entry}
                  errors={errors}
                  missing={missing}
                  handleListChange={handleListChange}
                  isAdmin={isAdmin}
                  pageType="g2h"
               />
            </Box>

            <ChangeLog isAdmin={isAdmin} onChange={handleChange} value={entry.changelog} />
            <CustomScript
               name="headBeginScript"
               label="HEAD Beginning Script"
               isAdmin={isAdmin}
               onChange={handleChange}
               entry={entry}
               errors={errors}
            />
            <CustomScript
               name="headEndScript"
               label="HEAD Ending Script"
               isAdmin={isAdmin}
               onChange={handleChange}
               entry={entry}
               errors={errors}
            />
            <CustomScript
               name="bodyBeginScript"
               label="BODY Beginning Script"
               isAdmin={isAdmin}
               onChange={handleChange}
               entry={entry}
               errors={errors}
            />
            <CustomScript
               name="bodyEndScript"
               label="BODY Ending Script"
               isAdmin={isAdmin}
               onChange={handleChange}
               entry={entry}
               errors={errors}
            />
         </div>
         <Frame
            frameBorder="none"
            className={frmClassName}
            head={
               <>
                  <style>{'html, body {background-color: #F6F6F6; margin: 0; padding: 0;}'}</style>
                  <base target="_blank" />
               </>
            }
            // #6973: Had to add Uploadcare's AD script directly in order to get it to process images in iFrame.
            initialContent={`<!DOCTYPE html><html><head>${previewStyles}</head><body><div class="frame-root">
                    <script>
                        (function(src, cb) {
                            var s = document.createElement("script");
                            s.setAttribute("src", src);
                            s.onload = cb;
                            (document.head || document.body).appendChild(s);
                        })("https://ucarecdn.com/libs/blinkloader/3.x/blinkloader.min.js", function() {
                            window.Blinkloader.optimize({
                                pubkey: "${process.env.GATSBY_UPLOADCARE_PUBLIC_KEY}",
                                fadeIn: true,
                                lazyload: true,
                                smartCompression: true,
                                responsive: true,
                                retina: false,
                                webp: true,
                            });
                        });
                    </script>
                    <script src="https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}"></script></div></body></html>`}>
            <GivingToHeroesPreview entry={{ ...entry, id }} fetchCollection={fetchCollection} />
         </Frame>
      </SplitPane>
   );
};

GivingToHeroes.propTypes = {
   classes: PropTypes.object.isRequired
};

export default withStyles(stylesExt)(GivingToHeroes);
