import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { mapBlogs } from '../../lib/utility';
import { img, imgBrand, alt, isBe } from '../../lib/brand';

import Layout from '../../components/Layout';
import Header from '../../components/Header';
import ProductHero from '../../components/ProductHero';
import ProductDetails from '../../components/ProductDetails';
import FindLO from '../../components/FindLO';
import BlogRoll from '../../components/BlogRoll';
import StaticText from '../../components/StaticText';
import styles from './styles.module.less';

const background = img('armPageBg.jpg');
const icon = imgBrand('typesImg/Icons_AdjustableRate_active.png');

const AdjustableRateMortgage = ({ staticTextItems }) => {
   const data = useStaticQuery(graphql`
      query AdjustableRateMortgageQuery {
         corpBlogs: allBlog(
            sort: { fields: [date], order: DESC }
            filter: { type: { eq: "corporate" }, tags: { in: ["Adjustable Rate Mortgages"] } }
         ) {
            edges {
               node {
                  id
                  html
                  slug
                  title
                  date
                  feature
                  categories
                  tags
                  image
                  author {
                     slug
                     profile {
                        name
                     }
                     gallery {
                        photo
                     }
                  }
                  date
                  feature
               }
            }
         }
      }
   `);

   const header = <Header active={['adjustable-rate-mortgage']} />;
   const { corpBlogs } = data;
   const { edges: corpBlobPosts = [] } = corpBlogs || {};
   const corporateBlogs = mapBlogs(corpBlobPosts);

   return (
      <Layout hasHero={true} header={header}>
         <Helmet>
            <title>{alt('Adjustable Rate Mortgage')}</title>
            <meta
               name="description"
               content="An adjustable-rate mortgage is a home loan where the interest rate changes periodically. Interested in getting an adjustable-rate home loan? Contact us today."
            />
            <meta
               name="keywords"
               content="adjustable rate mortgage,adjustable rate mortgage calculator,what is an adjustable rate mortgage,adjustable rate loan,adjustable mortgage rates,adjustable rate mortgage pros and cons,how does an adjustable rate mortgage work,adjustable rate mortgage vs fixed rate mortgage"
            />
         </Helmet>
         <ProductHero
            icon={icon}
            header={'Adjustable-Rate Mortgage'}
            subhead={subhead}
            customPhoto={background}
            alt={alt('Adjustable Rate Mortage')}
         />
         <ProductDetails title={'What is an adjustable-rate mortgage?'} border={true}>
            <StaticText id="adjustable-rate-mortgage-what-is" items={staticTextItems} className={styles.staticText} />
         </ProductDetails>
         <ProductDetails title={'What are the benefits of an adjustable-rate mortgage?'} border={true}>
            <ul style={{ margin: 0 }}>
               <li>Enjoy lowest possible rates during the initial fixed period</li>
               <li>Qualify with a lower interest rate for more benefits</li>
               <li>Choose a fixed-start period of 5, 7 or 10 years</li>
            </ul>
         </ProductDetails>
         <ProductDetails title={'What are the current rates for an adjustable-rate mortgage?'} border={true}>
            <StaticText
               id="adjustable-rate-mortgage-current-rates"
               items={staticTextItems}
               className={styles.staticText}
            />
         </ProductDetails>
         <ProductDetails title={'How do I qualify for an adjustable-rate mortgage?'} border={false}>
            <p>
               Qualifying for an adjustable-rate mortgage is simpler than you might think. We’ll simply check a few
               things such as your credit score before determining what loan program might work best for you.
            </p>
            <p className={styles.productSub}>Some adjustable-rate requirements</p>
            <ul className={styles.list}>
               <li>Ideally, a credit score of 740 or higher</li>
               <li>Suggested down payment between 5 and 20%</li>
            </ul>
         </ProductDetails>
         {isBe && (
            <>
               <FindLO />
               {corporateBlogs.length >= 3 && (
                  <BlogRoll
                     corporateBlogs={corporateBlogs}
                     blogs={corporateBlogs}
                     showBlogs={true}
                     word="our"
                     url="adjustable-rate-mortgages"
                  />
               )}
            </>
         )}
      </Layout>
   );
};

export default AdjustableRateMortgage;

const subhead = (
   <div>
      The rates may vary, but the benefits
      <br /> will always stay the same.
   </div>
);
