import React from 'react';
import StaticText from '../StaticText';
import Accent from '../Accent';
import styles from './styles.module.less';

type FlexDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse';

interface GetStartedBlockProps {
   icon: string;
   subtitle: string;
   title: string;
   description: string;
   flexD: FlexDirection;
   alt: string;
   staticTextItems: [];
}
const GetStartedBlock = (props: GetStartedBlockProps) => {
   const { icon, subtitle, title, description, flexD, alt, staticTextItems = [] } = props;
   return (
      <section className={styles.GetStartedBlock} data-background="dark">
         <div className={styles.container} style={{ flexDirection: flexD }}>
            <div className={styles.image}>
               <img src={icon} alt={alt} />
            </div>
            <div className={styles.text}>
               <div className={styles.title}>
                  <div className={styles.subtitle}>{subtitle}</div>
                  <StaticText id={title} items={staticTextItems} className={styles.title} />
               </div>
               <Accent align="left" />
               <StaticText id={description} items={staticTextItems} className={styles.description} />
            </div>
         </div>
      </section>
   );
};

export default GetStartedBlock;

GetStartedBlock.defaultProps = {
   bgColor: '#FFFFFF',
   flexD: 'row'
};
