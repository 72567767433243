import React, { Component } from 'react';
import styles from './styles.module.less';
import { img } from '../../../lib/brand';

var background = img('boots.jpg');

class CareersTop extends Component {
   constructor(props) {
      super(props);
      this.backRef = React.createRef();
      this.frontRef = React.createRef();
   }
   componentDidMount() {
      setTimeout(() => {
         if (this.frontRef.current) {
            this.frontRef.current.play();
         }
         return;
      }, 2000);
   }
   render() {
      let frontVideo = 'https://ucarecdn.com/eeb1c406-8b0c-425c-b432-d2ce95088766/ball.mp4';
      let backVideo = 'https://ucarecdn.com/12816d70-671d-42b0-86e8-fc63a0a58f84/fire.mp4';

      return (
         <section className={styles.CareersTop}>
            <div className={styles.bgVideo}>
               <video className={styles.back} ref={this.backRef} loop autoPlay muted playsInline>
                  <source src={backVideo} type="video/mp4" />
                  <source src={backVideo} type="video/ogg" />
                  <img src={background} title="Your browser does not support the video tag." alt="" />
               </video>
               <div className={styles.shape}>
                  <video className={styles.front} ref={this.frontRef} muted playsInline>
                     <source src={frontVideo} type="video/mp4" />
                     <source src={frontVideo} type="video/ogg" />
                  </video>
               </div>
            </div>
         </section>
      );
   }
}

export default CareersTop;
