import React from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { mapBlogs } from '../../lib/utility';
import { img, alt, isBe, siteNameShort } from '../../lib/brand';
import BlogRoll from '../../components/BlogRoll';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import IntroBlock from '../../components/IntroBlock';
import HeroLarge from '../../components/HeroLarge';
import GetStartedContent from '../../components/GetStartedContent';
import FindLO from '../../components/FindLO';

var getStartedHero = img('hero.jpg', 'get-started');

const GetStartedPage = ({ staticTextItems }) => {
   const data = useStaticQuery(graphql`
      query GetStartedPageQuery {
         corpBlogs: allBlog(
            sort: { fields: [date], order: DESC }
            filter: { type: { eq: "corporate" }, tags: { in: ["Getting Started"] } }
         ) {
            edges {
               node {
                  id
                  html
                  slug
                  title
                  date
                  feature
                  categories
                  tags
                  image
                  author {
                     slug
                     profile {
                        name
                     }
                     gallery {
                        photo
                     }
                  }
                  date
                  feature
               }
            }
         }
      }
   `);
   const header = <Header active={['first-time-homebuyer', 'get-started']} />;
   const intro = 'Every dream has a starting point';

   const { corpBlogs } = data;
   const { edges: corpBlobPosts = [] } = corpBlogs || {};
   const corporateBlogs = mapBlogs(corpBlobPosts);

   return (
      <Layout hasHero={true} header={header}>
         <Helmet>
            <title>{alt('Steps To Buying A Home - First-Time Homebuyer')}</title>
            <meta
               name="description"
               content={`Ready to start the home-buying process? Let ${siteNameShort}’s loan officers help you figure out what you can afford and get you pre-approved. Contact us today.`}
            />
         </Helmet>
         <HeroLarge customPhoto={getStartedHero} />
         <IntroBlock header={intro} id={'get-started-intro-text'} staticTextItems={staticTextItems} />
         <GetStartedContent staticTextItems={staticTextItems} />
         {isBe && (
            <>
               <FindLO />
               {corporateBlogs.length >= 3 && (
                  <BlogRoll
                     corporateBlogs={corporateBlogs}
                     blogs={corporateBlogs}
                     showBlogs={true}
                     word="our"
                     url="getting-started"
                  />
               )}
            </>
         )}
      </Layout>
   );
};

export default GetStartedPage;
